@font-face {
    font-family: "Oswald-Medium";
    src: url("//fonts.gstatic.com/s/oswald/v16/cgaIrkaP9Empe8_PwXbajPY6323mHUZFJMgTvxaG2iE.eot?#iefix") format("eot"), url("//fonts.gstatic.com/s/oswald/v16/cgaIrkaP9Empe8_PwXbajFtXRa8TVwTICgirnJhmVJw.woff2") format("woff2"), url("//fonts.gstatic.com/s/oswald/v16/cgaIrkaP9Empe8_PwXbajD8E0i7KZn-EPnyo3HZu7kw.woff") format("woff"), url("//fonts.gstatic.com/s/oswald/v16/cgaIrkaP9Empe8_PwXbajKCWcynf_cDxXwCLxiixG1c.ttf") format("truetype"), url("//fonts.gstatic.com/l/font?kit=cgaIrkaP9Empe8_PwXbajJbd9NUM7myrQQz30yPaGQ4&skey=4b4aed5676a34753&v=v16#Oswald") format("svg");
}

@font-face {
    font-family: "Poppins-SemiBold";
    src: url("//fonts.gstatic.com/s/poppins/v5/9VWMTeb5jtXkNoTv949NpVQlYEbsez9cZjKsNMjLOwM.eot?#iefix") format("eot"), url("//fonts.gstatic.com/s/poppins/v5/9VWMTeb5jtXkNoTv949Npfk_vArhqVIZ0nv9q090hN8.woff2") format("woff2"), url("//fonts.gstatic.com/s/poppins/v5/9VWMTeb5jtXkNoTv949NpRsxEYwM7FgeyaSgU71cLG0.woff") format("woff"), url("//fonts.gstatic.com/s/poppins/v5/9VWMTeb5jtXkNoTv949NpS3USBnSvpkopQaUR-2r7iU.ttf") format("truetype"), url("//fonts.gstatic.com/l/font?kit=9VWMTeb5jtXkNoTv949NpaWUboTb-jS2tyCOQMtm97g&skey=ce7ef9d62ca89319&v=v5#Poppins") format("svg");
}
.ckeditor-activate{
    align-self: center;
    width: 100%;
}
.activate-title {
    font: normal normal normal 70px/1.4em oswald-medium, oswald, sans-serif;
    color: var(--global-button-color);
    font-weight: 500;
}
.ck-rounded-corners .ck.ck-editor__editable:not(.ck-editor__nested-editable), .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-rounded-corners{
    height: 280px;
    width: 100%;
}
.region-city-fix {
    display: flex;
    flex-direction: row;
}

.minField100 {
    min-width: 100px !important;
}

.field150 {
    max-width: 150px;
}

.field180 {
    max-width: 300px;
}

.field80 {
    max-width: 80px;
}

.field50 {
    max-width: 50px;
}

.field100 {
    max-width: 100px;
}

.activate-subtitle {
    max-width: 611px;
    text-align: center;
    color: black;
    font-weight: 500;
    margin-top: 15px;
}

.terms-activate {
    font: normal normal bold 16px/1.4em 'arial black', arial-w01-black, arial-w02-black, 'arial-w10 black', sans-serif;
    color: var(--global-title-color);
    cursor: pointer;
    margin: 20px 0;
}

.activate-form-div {
    background-color: var(--global-second-bg-inner-color);
    width: 768px;
    min-height: 1000px;
    padding: 40px 100px;
    border: var(--global-button-color) 2px solid;
    display: flex;
    flex-direction: column;

}

.activate-form {
    display: flex;
    flex-direction: column;
}

.death-data {
    font: normal normal normal 25px/1.4em avenir-lt-w01_35-light1475496, sans-serif;
    font-family: poppins-semibold, poppins, sans-serif;
    letter-spacing: 0.04em;
    align-self: start;
    margin-top: 40px;
    color: black;
    text-align: left;
}

.activate-field {
    margin-top: 30px !important;
    min-width: 45% !important;
}

.activate-date-field {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.activate-checkbox {
    font: normal normal 12px/1.4em georgia, palatino, 'book antiqua', 'palatino linotype', serif;
    font-size: 13px !important;
    font-family: Arial, Helvetica, sans-serif;
    color: black;
    text-align: justify;
}

.activate-radio-title {
    margin-top: 15px;
    align-self: start;
    color: var(--global-sub-title-color);
}

.bottom-activate-form {
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#date-label.MuiInputLabel-filled.MuiInputLabel-formControl {
    margin-left: -10px;
}

/*#date-label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-filled.MuiFormLabel-filled{*/
/*    transform: translate(0px, 10px) scale(1)*/

/*}*/
.activate-submit {
    background-color: var(--global-button-color);
    border: 0;
    min-width: 200px;
    height: 70px;
    color: black;
    font-size: 20px;
    cursor: pointer;
    margin-left: auto;
    border-radius: 2px;
    font-weight: bold;
}

.activate-submit:hover {
    background-color: var(--global-hover-button-color);
    box-shadow: var(--global-button-shadow);
    color: white !important;
}

@media (max-width: 790px) {
    .activate-form-div {
        width: auto;
        padding: 40px 40px;
    }

    .activate-subtitle {
        margin: 50px 20px 0px 20px
    }

    .minField100 {
        /*min-width: 100px !important;*/
    }

    .field150 {
        max-width: 100%;
    }

    .field180 {
        max-width: 100%;
    }

    .field80 {
        max-width: 100%;
    }

    .field50 {
        max-width: 100%;
        width: 100% !important;
    }

    .field100 {
        max-width: 100%;
    }

    .partner-field.small.minField100, .partner-field.small.field50 {
        width: 100% !important;
    }

    .partner-field.small.minField100 {
        /*margin-left: 0;*/
    }
}

@media (max-width: 440px) {
    .activate-date-field {
        flex-direction: column;
    }

    .activate-form-div {
        width: auto;
        padding: 30px 15px;
    }

    .bottom-activate-form {
        flex-direction: column;
        align-items: center;
    }

    .activate-submit {
        margin: 40px 0;
        width: 100%;
    }

    .routes-label-devices {
        display: none;
    }

    .complete-root {
        margin-bottom: 20px !important;
    }

    .activate-title {
        font: normal normal normal 50px/1.4em oswald-medium, oswald, sans-serif;
    }

    .terms-activate {
        margin: 20px;
    }
}
