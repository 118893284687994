.qrcode-request-footer{
  position: absolute;
  width: 100%;
  height: 50px;
  top: 0px;
  left:0;
  background-color: var(--global-hover-button-color);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5);
  z-index: 31;
  display: flex;
  align-items: center;
  padding: 0 30px;
  font-family: Calibri;
  label{
    color: white;
    font-size: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    div{
      padding-top: 9px;
      cursor: pointer;
    }
  }
}
#request-form{
  width: 100%;
  height: auto;
  box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.5);
  margin: 10px 0;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .request-field{
    width: 80%;
  }
  .search-button{
    outline: none;
    font-family: Calibri;
    border: none;
    margin-top: 0 !important;
    margin-left: 20px;
  }
}
@media (max-width:600px){
  
  #request-form{
    flex-direction: column;
    .request-field{
      width: 100%;
    }
    .search-button{
    outline: none;
    font-family: Calibri;
    margin-left: 0px;
    border: none;
    margin-top: 20px !important;
  }
}
}