@font-face {
    font-family: "Futura-LT-W01-Book";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/cf053eae-ba1f-44f3-940c-a34b68ccbbdf.eot?#iefix") format("eot"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/8bf38806-3423-4080-b38f-d08542f7e4ac.woff2") format("woff2"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/e2b9cbeb-fa8e-41cd-8a6a-46044b29ba52.woff") format("woff"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c2a69697-4f06-4764-abd4-625031a84e31.ttf") format("truetype"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/dc423cc1-bf86-415c-bc7d-ad7dde416a34.svg#dc423cc1-bf86-415c-bc7d-ad7dde416a34") format("svg");
}
@media (max-width: 325px) {
    .activate-form-div{
        padding: 0 20px !important;
    }
}
.modalPurchase{
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    z-index: 30000;
    justify-content: center;
}
.buy-subtitle {
    margin-top: 40px;
    width: 768px;
    text-align: justify;
    font: normal normal bold 19px/1.4em barlow-extralight, barlow, sans-serif;
    display: flex;
    flex-direction: column;
    line-height: normal;
    color: black;
}
.buy-price {
    font-size: 25px;
    text-decoration: underline;
}
.buy-terms {
    font: normal normal normal 23px/1.4em futura-lt-w01-book, sans-serif;
    font-family: futura-lt-w01-book, sans-serif;
    text-decoration: underline;
    color: black;
    text-align: center;
    margin: 40px 0;
}

.buy-duty {
    text-align: center;
    font: normal normal normal 26px/1.4em avenir-lt-w01_35-light1475496, sans-serif;
    color: var(--global-sub-title-color);
}

.buy-duty-buttons {
    flex-direction: row;
    display: flex;
    width: 560px;
    justify-content: space-between;
    margin-top: 40px;
}

.click-buy-form{
    cursor: pointer;
}
.click-buy-form:hover{
    cursor: pointer;
    color: #0c85d0 !important;

}
.buy-duty-button {
    width: 111px;
    height: 111px;
    background-color: transparent;
    border: 3px solid var(--global-button-color);
    border-radius: 56px;
    font: normal normal normal 70px/1.4em helvetica-w01-roman, helvetica-w02-roman, helvetica-lt-w10-roman, sans-serif;
    color: var(--global-sub-title-color);
}
.buy-duty-button:hover{
    background-color: var(--global-button-color);
    color: white;
}
.buy-button-div {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
}

.buy-button-bottom-label{
    font: normal normal normal 26px/1.4em avenir-lt-w01_35-light1475496,sans-serif;
    color: /*rgb(96, 85, 230)*/ var(--global-sub-title-color);
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 40px;
}
.buy-form-paragraph{
    font: normal normal normal 23px/1.4em avenir-lt-w01_35-light1475496,sans-serif;
    font-family: futura-lt-w01-book,sans-serif;
    text-align: start;
    margin-top: 40px;
    color: black;
}
@media(max-width: 770px){
    .buy-subtitle{
        width: auto;
        margin: 0 15px;
    }
    .buy-terms{
        margin: 20px 15px;
    }
}
@media(max-width: 665px) {
    .buy-duty-buttons{
        width: auto;
        flex-direction: column;
        justify-content: flex-start;
    }
    .buy-button-div{
        width: auto;
        align-items: flex-start;
        flex-direction: row;
        margin-bottom: 40px;
        align-self: center;
    }
    .buy-duty-button{
        height: 70px;
        width: 100px;
        font-size: 40px;
    }
    .buy-button-bottom-label{
        width: 205px;
        margin-left: 0px;
        margin-top: 0;
    }
}
