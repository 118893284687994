.partner-form{
    display: flex;
    flex-direction: column;
}
.partner-main-div{
    border: var(--global-button-color) 2px solid;
    background-color: var(--global-second-bg-inner-color);
    padding: 25px 50px;
    width: 711px;
    height: auto;
    display: flex;
    flex-direction: column;
}
.file-upload{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--global-button-color);
    color: white;
    margin-top: 40px;
    height: 50px;
    border-radius: 8px;
    cursor: pointer;
}
.ck-content{
    padding: 0 20px !important;
    background-color: white;
    border: 1px solid #c4c4c4 !important ;

}
.Partner-row-fields{
    display: flex;
    flex-direction: row;

}
.partner-route{
    text-align: left;
    font-size: 19px;
}
.Partner-row-fields .partner-field{
    width: 100%;
}
.partner-field.small{
    width: 140px !important;
    margin-left: 20px;

}
.pointer{
    height: 100%;
    cursor: pointer;
}
.file-upload:hover{
    box-shadow: var(--global-button-shadow);
    background-color: var(--global-hover-button-color);
}
.partner-field{
    margin-top: 20px !important;
}

@media (max-width: 720px) {
    .partner-main-div {
        width: 99.5%;
    }
    .partner-field{
        width: 100%;
    }
}
@media (max-width: 400px) {
    .partner-main-div{
        padding: 25px 20px;
        width: 99.5%;
    }
    .recaptcha{
        padding: 10px;
    }
}
