body {
    margin: 0;
    width: -webkit-fill-available;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.labelDate {
    flex-direction: row !important;
    align-items: center !important;
    justify-content: flex-start !important;
}

.italic {
    font-style: italic;
}

.font-size-14 {
    font-size: 14px;
}

.uppercase {
    text-transform: uppercase;
}

.underline {
    text-decoration: underline;
}

.lowercase {
    text-transform: lowercase;
}

.success-notification-button {
    width: auto;
    align-self: center;
    margin-top: 20px;
    background-color: var(--global-button-color);
    outline: none;
    border: none;
    padding: 5px 15px;
    cursor: pointer;
}

.success-notification-button:hover {
    box-shadow: var(--global-button-shadow);
    background-color: var(--global-hover-button-color);
}

.fixed {
    position: fixed;
}

.absolute-center {
    left: 50%; /* Move 50% from left */
    transform: translateX(-50%);
}
.absolutexy-center {
    left: 50%; /* Move 50% from left */
    transform: translate(-50%);
    bottom: 50%; /* Move 50% from left */
    position: absolute;
}

.min-w-48 {
    min-width: 320px;
}

.text-14 {
    font-size: 14px;
}

.min-h-64 {
    min-height: 64px;
}

.justify-between {
    justify-content: space-between;
}

.align-center {
    align-content: center;
}

.items-center {
    align-items: center;
}

.bottom-0 {
    bottom: 0;
}

.border-1 {
    border: 1px solid;
}

.shadow-3 {
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12)
}

.p-20 {
    padding: 20px;
}

.relative {
    position: relative;
}

@media (max-width: 700px) {
    .alert-container div {
        width: 100%;
    }

}

@media (max-width: 550px) {
    .min-w-500-alert {
        min-width: auto !important;
    }

    .entry-container {
        flex-direction: column;
    }

    .tocenter {
        justify-content: center;
    }
    .flex-1-mobile{
        flex: 1;
    }
}

.alert-container {
    display: flex;
    margin: auto;
    align-self: center;
    justify-self: center;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    background-color: white;
    padding: 20px;
    z-index: 20000;
    border-radius: 8px;
    min-height: 144px;
}

.alert-text {
    font-size: 12px
}

.info-dedic {
    right: 20px;
    top: 0;
    position: absolute;
    cursor: pointer;
}

.alert-title {
    font-size: 17px;
}
.flex-1{
    flex: 1;
}
.dedic-info-container {
    display: flex;
    color: var(--global-sub-title-color);
    gap: 4px;
}

@media (max-width: 635px) {
    .dedic-info-container {
        flex-direction: column;
    }
}

.alert-text, .alert-title {
    text-align: center;
}

.min-w-500-alert {
    min-width: 500px;
}

.entry-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.hover-scale:hover {
    transform: scale(1.2);
}

@media (max-width: 600px) {
    .give-property-media {
        width: calc(100% - 20px) !important;
        overflow-y: auto;
    }

    .pagination-mobile {
        height: 50px;
    }

    .pagination-mobile > div.MuiTablePagination-toolbar {
        position: absolute;
        margin-top: -25px;
    }
}

@media (max-width: 500px) {
    .memorial-modify-fix-media .activate-submit {
        margin-left: 0 !important;
    }

    .memorial-modify-fix-media {
        flex-direction: column !important;
        align-items: center;
        gap: 20px;
    }

    .memorial-chip {
        top: -45px !important;
        right: 5px
    }

    .pagination-mobile .MuiTablePagination-selectRoot {
        margin-left: -8px;
    }

    .pagination-mobile .MuiTablePagination-caption:nth-child(2) {
        display: none !important;
    }
}

@media (max-width: 370px) {
    .partner-page-footer {
        font-size: 13px !important;
    }
}
div.MuiPopover-root,.MuiDialog-root{
    z-index: 3002 !important;
}