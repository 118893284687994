@media (max-width: 400px) {
  .login-page{
    .media-fix{
      width: 100% !important;
      padding: 40px 30px !important;
    }
    .login-form{
      width: 300px ;
    }
  }
  #bottom-login {
    display: flex;
    flex-direction: row;
    width: auto !important;
    align-items: center;
    margin-bottom: 8px;
    justify-content: space-between;
  }
}
.registration .notification-main-container {
  margin-right: 40px;
}
@media(max-width: 700px) {

  .registration .notification-main-container {
    margin-right: 0px;
  }
}
.columns{
  height: 700px;

}
.login-page{
  background: linear-gradient(to right, #ffaa00, #eac74c);
  margin: 0 !important;
  .column{
    background-color: white;
    //margin-top: 40px;
    align-self: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 400px ;
    padding: 40px;
    box-shadow: rgba(0,0,0,0.4) 0 0 5px 1px;
    border-radius: 5px;
    .death-data{
      text-align: center;
      margin-top: -13px;
      margin-bottom: 0px;
      align-self: center;
    }
    p{
      width: auto;

    }

    .login-form{
      width: 320px;
      //margin-bottom: 20px;

    }
    .button.is-primary{
      color: black !important;
      border:none !important;
      background-color: var(--global-button-color) !important;
    }
    .button.is-primary:hover,.button.is-primary:focus{
      color: white !important;
      background-color: var(--global-hover-button-color) !important;
      border:none !important;
      box-shadow: rgba(0,0,0,0.1) 0 3px 5px 1px;
    }
    #bottom-login{
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      margin-bottom: 8px;
      justify-content: space-between;
    .activate-checkbox{
      align-content: center;
      align-items: end;
      font-style: normal;
      align-self: start;
      margin-left: -10px;
    }}
  }

}
#footer-login{
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  .divider-login{
    height: 2px;
    width: 25px;
    margin: 0 9px;
    background-color: grey;
    margin-bottom: 6px;
  }
}
.field.is-clearfix{
  width: 320px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 8px;
  button{
    width: 200px;
  }
}
.mb-20{
  margin-bottom: 20px;
}

.login-remember{
  color: black;
 font-style: normal;
}
.not-registered{
  margin-top: 20px;
}
#click-here{
  cursor: pointer;
  color: var(--global-button-color);
}
#click-here:hover {
  color: #4c2f0c;
}
.registration{
  max-width: 700px !important;
  .login-form{
    max-height: 90px;
    margin-bottom: 20px !important;
  }
  .MuiFormHelperText-root.Mui-error{
    margin: 0;
    width: auto;
  }
}
