.obituary-main-container{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
height: 100%;
  z-index: 31;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.8);
  .obituary-card{
    min-width: 250px;
    min-height: 250px;
    border-radius: 5px;
    background-color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    label{
      font-size: 22px;
      font-weight: 500;
      color: var(--global-button-color);
      margin-bottom: 20px;
    }
    div{
      margin-top: 16px;
    }
    button{
      outline: none;
      cursor: pointer;
      border:none;
      margin-top: auto;
      background-color: #ffaa00;
      color: black;
      padding: 10px 20px;
      width: 100px;
      align-self: flex-end;
    }
    button:hover{
      background-color: var(--global-hover-button-color);
      box-shadow: var(--global-button-shadow);
    }
  }
}
.check-search {
  margin-top: 20px;
  .MuiCheckbox-root {
    color: var(--global-button-color) !important;
  }
}
