@import url('https://fonts.googleapis.com/css?family=Cookie:regular,bold,italic&subset=latin,latin-ext');

.bg {
    /*width: 2200px;*/
    /*background-image: url("../../assets/bgHome.webp");*/
    /*height: 430px;*/
    overflow: hidden;
    background-size: 1600px 1512px;
    background-position-y: 1102px;
    background-position-x: center;
}

.aphorism-fix {
    font-size: 20px !important;
}

.result-img {
    height: 150px;
    min-height: 150px;
    max-height: 150px;
    align-self: start;
    border-radius: 5px;
    box-shadow: 0 0 3px 3px;
    max-width: 214px;
    min-width: 214px;
    object-fit: cover;
}

.home-lamp-icon {
    object-fit: cover;
    background-size: cover;
    height: 114px;
    align-self: center;
}

.lampIcon {
    right: 128px !important;
    top: 64px !important;
    width: 60px !important;
}

.bottom-fix-margin {
    margin-top: 0 !important;
    z-index: 30;
    background-color: #000 !important;
    box-shadow: rgba(0, 0, 0, 0.7) 10px 10px 10px 10px;

}

h1 {
    display: block;
    font-size: 2em !important;
    margin: 0.67em 0 !important;
}

h2 {
    display: block;
    font-size: 1.5em !important;
    margin: 0.83em 0 !important;
}

h3 {
    display: block;
    font-size: 1.17em !important;
    margin: 1em 0 !important;
}

ul {
    display: block !important;
    list-style-type: disc !important;
    padding-left: 20px !important;
}

.search-first-row {
    display: flex;
    flex-direction: row;

}

.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.overflow-none.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
    overflow-x: clip !important;
}

.secondary-div {
    background-color: transparent;
    max-width: 1100px;
    height: 430px;
    margin: auto;
    margin-top: 80px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

div.search-result {
    background-color: white;
    transition: linear;
    transition-duration: 0.05s;
}

div.search-result:hover {
    transform: scale(1.035) translateZ(0);
    color: rgb(74, 74, 74) !important;
    box-shadow: rgba(255, 120, 0, 0.8) 0px 0px 7px 1px;
}

.elipsis-search {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.toColumn {
    width: 100%;
}

.ml--5 {
    margin-left: -5px;
}

.date {
    margin-top: 45px;
    width: 220px;
}

.w-full {
    width: 100%;
}

.file-name-div {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-top: 8px;
    padding-right: 5px;
}

.file-name-label {
    text-overflow: ellipsis;
    width: 100%;
    overflow-x: hidden;
}

.files-name-div {
    display: flex;
    flex-wrap: wrap;
}

.remove-file-icon {
    color: red;
    margin-right: 20px;
    cursor: pointer;
}

.files-name-column {
    width: 50%;
}

.paginator-arrow {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.margin-paginator-fix {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.overflow-none.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
    overflow-x: hidden;
}

.overflow-none, .MuiMenuItem-root {
    overflow-x: hidden;
}

.first-row-buttons {
    flex-direction: row;
    width: 100%;
    display: flex;
    justify-content: space-around;
    max-width: 975px;

}

.MuiTypography-root.MuiPickersToolbarText-toolbarTxt.MuiPickersToolbarText-toolbarBtnSelected.MuiTypography-h4.MuiTypography-alignCenter, .MuiTypography-root.MuiTypography-body1.MuiTypography-alignCenter {
    text-transform: capitalize;
}

.ml-0 {
    margin-left: 0;
}

.mr-0 {
    margin-right: 0;
}

.font-bold {
    font-weight: bold;
}

.terms-link:hover {
    color: var(--global-button-color) !important;
}

.terms-link {
    color: black;
    font-weight: bold;
    text-decoration: underline;
}

.first-row-element {
    flex: 1;
}

.element {
    cursor: pointer;
    background-color: black;
    color: white;
    padding: 20px;
    margin: 20px;
    font: normal normal bold 16px/1.4em 'arial black', arial-w01-black, arial-w02-black, 'arial-w10 black', sans-serif;
}

.search-result {
    width: 926px;
    height: 200px;
    border-radius: 10px;
    align-self: center;
    margin-top: 20px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 7px 1px;
    display: flex;
    flex-direction: column;
    padding: 20px;

}

.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiInput-input {
    margin-bottom: 5px;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorSecondary.MuiInput-colorSecondary.MuiInputBase-formControl.MuiInput-formControl {
    background-color: white;
    text-align: left;
    padding: 0 13px;
}

.absolute-book-partner {
    position: absolute;
    width: 55px;
    height: 45px;
    right: 20px;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    top: 70px;
    zIndex: 31
}

.absolute-book-partner-bottom {
    position: absolute;
    width: 50px;
    height: 50px;
    right: 20px;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    top: 120px;
    zIndex: 31
}

.absolute-book {
    position: absolute;
    width: 50px;
    right: 20px;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    top: 20px;
    zIndex: 31
}

.inner-search {
    width: 890px;
    height: 160px;
    align-self: center;
    display: flex;
    flex-direction: row;
    min-height: 160px !important;
    color: rgb(74, 74, 74);
}

a.inner-search:hover {
    color: rgb(74, 74, 74) !important;
}

.search-box-div {
    max-width: max-content;
    justify-self: center;
    margin: auto;
    margin-top: 20px;
    padding: 20px 0;
}

.check-search {
    color: white;
}


.MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiFilledInput-adornedEnd {
    border-radius: 4px 4px 0 0;
    border-start-end-radius: 4px;
    border-start-start-radius: 4px;

}

.MuiFormControl-root.field.w-100perc .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.Mui-focused {
    transform: translate(14px, 6px) scale(0.75) !important;
    border-radius: 0 !important;
}

@media (max-width: 361px) {
    .secondary-div, .bg {
        height: 700px !important;

    }
}

@media (max-width: 331px) {
    .secondary-div, .bg {
        height: 800px !important;

    }
}

@media (max-width: 440px) {
    .date {
        width: 100%;
        margin-left: -5px !important;
    }
}

.no-elem label {
    height: 60px;
    display: flex;
    align-self: center;
    margin: auto;
    text-align: center;
    justify-content: center;
    max-width: 900px;
    border: 2px solid;
    align-content: center;
    align-items: center;
    padding-bottom: 3px;
    margin-top: 20px;
}

@media (max-width: 960px) {
    .absolute-book, .absolute-book-partner, .absolute-book-partner-bottom {
        top: auto;
        bottom: 20px;
    }

    .home-cap {
        width: 100% !important;
        margin-left: 0 !important;
        border-radius: 4px;
        margin-bottom: 10px;
        margin-top: 10px;
        padding-right: 7px !important;
    }

    .home-cap input {
        text-align: center !important;
        margin-right: 33px !important;
    }

    .partner-memorial {
        right: 10px;
        top: 17%;
    }

    .chip {
        left: 25px !important;
        margin: auto;
        right: 10px !important;
    }

    .absolute-book {
        right: 10px;
    }

    .absolute-book-partner {
        right: 12px;
        top: 8%;
        height: 45px;
        width: 45px;

    }

    .absolute-book-partner-bottom {
        right: 12px;
        bottom: 10px !important;
        top: auto;
        left: 20px;
        height: 50px;
        width: 50px;

    }

}

@media (max-width: 493px) {
    .secondary-div, .bg {
        height: 690px !important;

    }
}

.home-background {
    background: linear-gradient(to right, #ffaa00, #eac74c);
    position: relative;
}
.show-all-home-button{
    position: absolute !important;
    bottom: 170px;
    left: 50%;
    transform: translateX(-50%);
}
@media (max-width: 960px) {

    .search-box-div {
        width: 100% !important;
        max-width: 90%;
        margin-top: 20px;

    }

    .inner-search {
        justify-content: flex-start;
    }

    .memorial-book-paginator {
        width: 96% !important;
    }

    .search-result, .inner-search {
        width: 100%;
        height: auto
    }

    .result-img {
        margin: 0;
        min-height: 150px;
        min-width: 170px;
    }

    .search-box.mt-20 {
        margin-top: 0 !important;
    }

    .subtitle {
        width: 100%;
    }

    .first-row-buttons {
        flex-wrap: wrap;
    }

    .first-row-element {
        display: flex;
        width: 300px;
        height: 84px;
        justify-content: center;
        align-content: center;
        alignment-baseline: center;
        align-items: center;
        align-self: center;
        justify-self: center;

    }

    .secondary-div, .bg {
        height: 600px;

    }

    .search-first-row {
        flex-direction: column;
    }

    .search-first-row .field, .field.w-100perc {
        margin: 15px 0 !important;
        width: 100% !important;
        border-radius: 4px !important;
    }

    .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiFilledInput-adornedEnd {
        border-radius: 4px;
    }

    .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiFilledInput-adornedEnd {
        border-radius: 4px;

    }

    .MuiFormControl-root.field.w-100perc .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.Mui-focused {
        border-radius: 4px !important;
    }

    .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorSecondary.MuiInput-colorSecondary.MuiInputBase-formControl.MuiInput-formControl {
        border-radius: 4px;
    }

    .search-button {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiFilledInput-adornedEnd {
    height: 53px;
    background-color: white;
}

.MuiTypography-root.MuiPickersToolbarText-toolbarTxt.MuiTypography-h4.MuiTypography-alignCenter {
    text-transform: capitalize;
}

#card-content-span {
    /*max-width: 400px;*/
    min-height: 40px;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis
}

@media (max-width: 681px) {
    .field.w-90perc {
        margin-top: 0 !important;
    }
}

@media (max-width: 430px) {
    .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiInput-input {
        margin-left: 10px;
    }

    .search-first-row .field {
        margin: 10px 0 !important;
        text-align: left;
    }

}

@media (max-width: 490px) {
    .second-row-buttons {
        flex-direction: column !important;
    }

    .secondary-div, .bg {
        background-size: cover;
    }

    .second-row-element {
        width: 300px !important;
        height: 62.5px !important;
        margin-top: 0 !important;
    }

    .demo {
        align-self: center;
    }

    .subtitle {
        font-size: 20px !important
    }

}

.element:hover {
    cursor: pointer;
    background-color: white;
    color: black;

}

.second-row-buttons {
    display: flex;
    flex-direction: row;
}

.second-row-element {
    display: inline-grid;
    width: 200px;
    height: 200px;
    place-content: space-evenly
}

.qr-container {
    display: flex;
    flex-direction: column;
}

.ml-13 {
    margin-left: 13px !important;
}

.demo {
    cursor: pointer;
    padding: 20px;
    margin: 20px;
    width: 200px;
    height: 200px;
    background-color: white;
    display: inline-grid;
    place-content: space-evenly
}

.qr-label {
    width: fit-content;
    font: normal normal bold 13px/1.4em 'arial black', arial-w01-black, arial-w02-black, 'arial-w10 black', sans-serif;
    align-self: center;
    background-color: white;
    padding: 3px 9px;
    margin-top: -12px;
    cursor: pointer;
}

.social-logo {
    margin: 7px;
    margin-top: 25px;
    width: 30px;
    filter: invert(77%) sepia(62%) saturate(4277%) hue-rotate(4deg) brightness(93%) contrast(87%);
}

.qr-label:hover {
    background-color: black;
    color: white;
}

.email {
    color: white !important;
    text-decoration: underline !important;
    cursor: pointer;
}

.email:hover {
    color: #cecece !important;
    text-decoration: none !important;
}

.termini {
    margin: 29px;
    color: white !important;
}

.contact {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.bottom-label {
    color: white;
}

.bottom {
    display: flex;
    flex: 1;
    background-color: #5e95fd;
    height: 150px;
}

.search-bar {
    width: 300px;
    margin-top: 20px;
    min-height: 50px;
    height: 60px;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    border-radius: 5px;
    border: #cecece 2px solid;
}

.search-bar-label {

    color: #cecece;
    font-size: 25px;
}

.third-div {
    padding: 0 20px 20px 20px;
    display: flex;
    flex-direction: column;
}

.search-button-label {
    cursor: pointer;
}

.search-button {
    font-size: 35px;
    /*margin-top: 20px;*/
    align-self: end;
    text-align: center;
    padding: 5px;
    background-color: #ffaa00;
    width: 150px;
    font-family: cookie, cursive;
    cursor: pointer;
    color: black;
    border-radius: 2px;

}

.search-button:hover {
    background-color: var(--global-hover-button-color);
    color: inherit;
    box-shadow: var(--global-button-shadow);
}

.subtitle {
    display: flex;
    flex-direction: column;
    background-color: #000000;
    border-radius: 10px;

    color: #ffaa00 !important;
    font-family: cookie, cursive;
    font-size: 42px !important;
    padding: 18px 20px;
    box-shadow: rgba(0, 0, 0, 0.7) 5px 5px 7px 3px;
    margin-top: 0px !important;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.width700 {
    width: 700px !important;
}
.force-bold textarea{
    font-weight: bold !important;
}
.force-red textarea{
    color: red !important;
}
.search-result-data label, .label-fix {
    max-width: 520px !important;
    font: normal normal normal 31px/1em cookie, cursive;
    font-size: 28px;
    text-align: start;
    font-family: Futura-LT-W01-Book, sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}

.label-fix {
    text-align: center;
    align-self: center;
    margin-bottom: 5px;
}

.home-cap .MuiInputLabel-shrink {
    transform: translate(10px, 6px) scale(0.75) !important;
}

.home-cap .MuiAutocomplete-input {
    padding-left: 10px !important;
}

#address .MuiAutocomplete-clearIndicator, .no-x-auto .MuiAutocomplete-clearIndicator {
    display: none;
}
.MuiAutocomplete-popper{
    z-index: 20000 !important;
}
.MuiAutocomplete-popper ul {
    padding-left: 0 !important;
}

#address .MuiAutocomplete-option, .no-x-auto .MuiAutocomplete-option {
    padding-left: 10px;
}

.no-x-auto .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot, #address .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    padding-right: 0;
}

.home-cap .MuiInputLabel-root {
    transform: translate(12px, 14px) scale(1);
}

.chip-style {
    width: 155px;
    height: 40px;
    font-size: 14px;
    border-radius: 20px;
    color: white;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 80px;
}

.dedic-chip {
    background-color: black;
    width: 200px;
    position: absolute;
    top: 17px;
    left: 50%;
    transform: translate(-50%);
}

@media (max-width: 621px) {
    .dedic-chip {
        top: -15px;
    }
}

.modalPreview {
    max-width: 200px;
    height: auto;
}

@media (max-width: 600px) {
    label.label-fix {
        max-width: 100% !important;
    }

    .home-lamp-icon {
        height: 58px !important;
    }
    
    .lampIcon {
        right: 92px !important;
        top: 38px !important;
        width: 60px !important;
    }

    .subtitle {
        padding: 5px 20px;
    }

    .search-result {
        padding: 10px 15px;

    }

    .search-result label {
        justify-content: center;
    }

    #card-content-span {
        min-height: 34px;
        font-size: 25px;
        margin-bottom: 2px;
    }

    .font-size-19 {
        font-size: 15px !important;
    }

    .inner-search {
        flex-direction: row !important;
    }

    .search-result-data {
        margin-top: 0 !important;
        margin-left: 15px;
    }

    .result-img {
        max-width: 213px !important;
        max-height: 150px;
        min-width: 150px !important;
        min-height: 213px;
        margin: 0 !important;
        /*max-width: 100% !important;*/

    }

    .result-img.oval {
        margin: 0 36px !important;
        min-width: 105px !important;
    }

    .absolute-book-partner {
        right: 12px;
        top: 17%;
        height: 45px;
        width: 45px;

    }

    img.absolute-book-partner {
        top: 17%
    }
    img.absolute-book-partner.obituary {
        top: 17%
    }
    .search-result-data {
        width: auto;

        margin-top: 20px;
    }

}

@media (max-width: 340px) {
    .absolute-book-partner {
        right: 6px !important;
    }

}

.chip2 {
    background-color: black !important;
    cursor: pointer !important;
    width: 130px !important;
    height: 28px !important;
    min-height: 28px !important;
    font-size: 14px !important;
    left: 26% !important;
    bottom: 59px !important;
    top:unset!important;
    margin: 0 !important;
}

@media (max-width: 960px) {
    .chip2 {
        left: 240px !important;
        bottom: 113px !important;
    }
}
@media (max-width: 550px) {
    .noHeight{
        min-height: unset !important;
        height: max-content !important;
    }
    .chip2 {

        left: 154px !important;
        bottom: 113px !important;
    }
}

@media (max-width: 400px) {
    .chip2 {
        bottom: 29% !important;
right: unset!important;
        left: 42% !important;
    }
}
