@import url('https://fonts.googleapis.com/css?family=Cookie:regular,bold,italic&subset=latin,latin-ext');

@font-face {
    font-family: "Barlow-ExtraLight";
    src: url("//fonts.gstatic.com/s/barlow/v1/51v0xj5VPw1cLYHNhfd8NPY6323mHUZFJMgTvxaG2iE.eot?#iefix") format("eot"), url("//fonts.gstatic.com/s/barlow/v1/51v0xj5VPw1cLYHNhfd8NFtXRa8TVwTICgirnJhmVJw.woff2") format("woff2"), url("//fonts.gstatic.com/s/barlow/v1/51v0xj5VPw1cLYHNhfd8ND8E0i7KZn-EPnyo3HZu7kw.woff") format("woff"), url("//fonts.gstatic.com/s/barlow/v1/51v0xj5VPw1cLYHNhfd8NKCWcynf_cDxXwCLxiixG1c.ttf") format("truetype"), url("//fonts.gstatic.com/l/font?kit=51v0xj5VPw1cLYHNhfd8NJbd9NUM7myrQQz30yPaGQ4&skey=e8c74abecf94633e&v=v1#Barlow") format("svg");
}

@media (max-width: 370px) {
    .text-container-what {
        padding: 0 20px;
    }
}

.text-container-what {
    max-width: 535px;
    margin: 0 auto;
    background-color: var(--global-bg-inner-color);
}

.main-div {

    background-color: var(--global-bg-inner-color);
}

.what-text-info {
    margin-top: 40px;
    font: normal normal normal 31px/1.4em cookie, cursive !important;
    font-size: 31px !important;
    color: black;
    line-height: 60px;

}

.sign {
    margin-top: -8px;
    text-align: end;
    margin-bottom: 10px;
}

.citation {
    max-width: 261px;
    text-align: center;
    margin: auto;
    line-height: 35px;
    margin-top: 40px;

}

.title-what {
    font: normal normal normal 50px/1.4em Barlow-ExtraLight, barlow, sans-serif;
    margin-top: 25px;
    margin-bottom: 25px;
    color: #000;
}
