.bg-white.dashboard {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: #ffffff !important;

  .datePopup {
    display: flex;
    background-color: #fff;
    box-shadow: 0 0 10px 1px rgba(0,0,0,.1);
    flex-direction: row;
    align-self: center;
    width: calc(100% - 15px)!important;
    border-radius: 5px;
    justify-content: space-evenly;
    margin: 15px 5px 5px;
    max-width: 1300px;
  }
  .dateInput {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin: 8px 20px 20px 20px;
    min-height: 60px;
    min-width: 240px;
    width: 100%;
  }
  .dateLabel {
    font-size: 14px;
    color: #4a4a4a;
    transform: translate(0px, 14px) scale(1);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }
  .dateField{
    width: 100%;;
    margin-bottom: 0px;
    text-align: left !important;
  }
  .dateButtonDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 20px;
    min-height: 50px;
  }
  .dateButton {
    height: 45px!important;
    color: #000!important;
    font-size: 18px;
    font-weight: 550 !important;
    margin: 0px !important;
    border-radius: 2px;
    min-width: 180px !important;
    border-radius: 5px;
  }
  @media(max-width: 1260px) {
    .datePopup { flex-wrap: wrap; }
    .dateButton { width: 100%; }
    .dateButtonDiv { width: 100%; }
    .dateInput { margin: 8px 20px 5px; }
  }

  #second-in {
    display: flex;
    flex-direction: column;
    height: auto;

    #upper {
      display: flex;
      background: linear-gradient(90deg,#fa0,#ffdd79);
      flex-direction: column;
      height: 450px;
      width: 100%;
      justify-content: flex-start;

      #card-row {
        display: flex;
        flex-direction: row;
        align-self: center;
        width: 1380px;
        padding: 9px 40px;
        justify-content: center;

        #card, #card0 {
          cursor: pointer;
          width: calc(50% - 10px) !important;
          border-radius: 5px;
          height: 120px;
          margin-left: 20px;
          background-color: white;
          box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px 1px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 20px;

          #icon {
            background-color: #fd5d5d;
            height: 27px;
            width: 27px;
            min-width: 27px;
            min-height: 27px;
            margin-right: -10px;
            margin-top: -10px;
            border-radius: 100%;
            align-items: center;
            justify-content: center;
            color: white !important;
          }

          div {
            display: flex;
            flex-direction: column;
            justify-content: center;

            label {
              font-size: 24px;
              text-align: left;
              margin-bottom: 0;
              color: black;
              cursor: pointer;
            }

            #title-card {
              color: grey;
              font-size: 13px;
              cursor: pointer;
            }
          }
        }

        #card:hover, #card0:hover {
          opacity: 0.7;
        }
      }
    }

    @media (max-width: 461px) {
      #preferences-panel {
        width: 260px;
        padding: 0 20px;
        align-self: center;
      }
    }
    @media (max-width: 350px) {
      .field.is-clearfix {
        width: 100%;
      }
      #preferences-panel {
        margin-left: 10px;
        align-self: start;
        width: 250px;
        padding: 0 10px;
      }
    }

    #content {
      padding: 30px;
      width: 1260px;
      margin-top: -200px;
      height: 100%;
      margin-bottom: 50px;
      background-color: #ffffff;
      align-self: center;
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.12) 0 0 10px 1px;

      .panel-title-div {
        align-items: center;
        display: flex;
        justify-content: center;

        #modify-icon {
          cursor: pointer;
        }
      }

      .admin-content-subtitle {
        font-size: 35px;
      }

      #collapsable-container {
        box-shadow: rgba(0, 0, 0, 0.2) 0 0 4px 1px;

        .collapsable-list {
          padding: 13px 15px;
          align-items: center;
          justify-content: center;
          align-content: center;
          cursor: pointer;
          background-color: white;
          border-top: 1px solid rgba(0, 0, 0, 0.2);

          label {
            cursor: pointer;
          }
        }
      }

      #activation {
        display: flex;
        flex-direction: column;

        #activation-card {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100px;

          #content-card {
            margin-left: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;

            #anagraphic {
              display: flex;
              flex-direction: column;
              margin-left: 40px;
              justify-content: center;

              label {
                margin-bottom: 0 !important;
              }
            }
          }

          #divider {
            align-self: center;
            width: 95%;
            height: 2px;
            background-color: lightgray;
          }
        }
      }
    }
  }
}

.place-fix {
  place-items: baseline !important;
  padding: 10px 0 !important;
}

.poppins {
  font-family: Poppins-SemiBold, sans-serif !important;
  font-style: normal !important;
}

.collapsable-list:hover {
  background-color: rgba(255, 169, 0, 0.15) !important;
}

.admin-table {
  min-width: 1100px;

  tbody tr {
    height: 100px;
  }
}

.admin-table2 {
  //min-width: 850px;
  //width: 850px;
}

@media(max-width: 730px) {
  .partner-title-150 {
    margin-top: -10px !important;
    margin-bottom: 150px !important;
  }
  .partner-title-80 {
    margin-top: -10px !important;
    margin-bottom: 80px !important;
  }
  .contact-container {
    width: auto !important;
  }
  #upper {
    height: 880px !important;
  }
  #card {
    width: 96% !important;
  }
  #card-row {
    width: 100% !important;
  }
  #purchase-modal {
    width: 100% !important;
  }
  #content {
    padding-bottom: 150px !important;
  }
  .extra-button-main-style,.panel-button-resize {
    left:5px !important;
    width: calc(100% - 10px) !important;
    top: 80px !important;
    justify-content: center !important;
    bottom: unset !important;
  }
  .extra-button-main-style2{
    bottom:70px !important;
    top: unset !important;
  }
}

#activate-button-table:hover {
  background-color: var(--global-hover-button-color);
  color: white;
}

@media(max-width: 500px) {
  .admin-content-subtitle {
    font-size: 28px !important;
  }
}

#activate-button-table {
  cursor: pointer;
  color: black;
  width: 100px;
  height: 40px;
  background-color: var(--global-button-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}


#preferences-panel {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media(max-width: 1260px) {
  #content {
    width: 100% !important;
  }
  #upper {
    height: auto !important;
    padding-bottom: 200px;
  }
  #card-row {
    width: 100% !important;
    flex-wrap: wrap;
    padding: 13px 3px !important;

    #card, #card0 {
      margin: 5px !important;
    }
  }

}

@media(max-width: 350px) {
  .card-fix {
    margin: 10px 0 !important;
  }
}

.admin-panel-modal {
  position: fixed;
  z-index: 31;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  align-self: center;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  align-content: center;
  padding: 20px 40px 40px 40px;

  #crossIcon {
    align-self: flex-end;
    font-size: 37px;
    color: white;
    margin-right: -30px;
    margin-left: auto;
    cursor: pointer;
    display: flex;
  }

  .flex {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-self: center;
    align-content: center;
    align-items: center;
  }

  div {
    #purchase-modal {
      background-color: white;
      padding: 40px;
      border: #ffaa00 2px solid;
      display: flex;
      flex-direction: column;
      width: 700px;

      #title-modal {
        color: var(--global-sub-title-color);
        font-weight: bold;
        font-size: 37px;
        font-style: italic;
        align-self: center;
      }

      p {
        text-align: left;
      }
    }
  }
}

.admin-menu-toggler {
  position: fixed !important;
  bottom: 100px !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
  border-radius: 44px !important;
  max-width: 54px !important;
  border: 3px solid rgba(0, 0, 0, 0.3) !important;
  color: rgba(0, 0, 0, 0.3) !important;
  font-weight: bold !important;
  height: 37px;

}

//thead,tbody{
//  tr{
//    th,td{
//      max-width: 250px !important;
//      width: 250px !important;
//    }
//  }
//}


.table-cell-props {
  max-width: 250px;
  width: 250px;
  text-align: start;
  overflow: auto;

  p {
    text-align: center;
  }
}

.MuiTableCell-footer {
  border-bottom: 0 !important;
}

#page-detail {
  display: flex;
  flex-direction: row !important;
  align-content: center;
  width: 100%;
  justify-content: space-between !important;
  align-items: center;
}

tfoot {
  tr {
    td {

      div {
        overflow: auto;
        display: flex !important;
        flex-direction: row !important;
        align-self: center;
        margin-left: 8px;
      }
    }
  }
}

.bottom-modal-submit {
  width: 220px;
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  justify-content: space-between;

  .button {
    width: 95px;
  }

  .button:hover {
    background-color: var(--global-hover-button-color);
  }
}

.extra-button-icon-style {
  font-size: 19px;
  margin-right: 5px;
  color: inherit;
  transition: 0.15s;
}

button:hover .extra-button-icon-style {
  color: black;
}

.extra-button-main-style {
  position: absolute;
  padding: 10px 15px !important;
  width: auto ;
  border: 0;
  font-family: Calibri !important;
  font-size: 20px !important;
  align-items: center;
  display: flex;
  top: 17px;

}
.extra-button-main-style2 {
  position: absolute;
  padding: 10px 15px !important;
  width: auto !important;
  border: 0;
  font-family: Calibri !important;
  font-size: 20px !important;
  align-items: center;
  display: flex;
  top: 17px;

}
.cool-effect {
  //color: transparent;
  max-height: 44px;
  min-height: 44px;
  transition: 0.3s all linear;
  max-width: 59px;
  overflow: hidden;

  #text {
    color: transparent;
    //display: none;
    //min-width: 200px;
    height: 44px;
    padding-top: 12px;
    transition: 0.1s;
    overflow: hidden;
  }
}

.cool-effect:hover {
  //color: transparent;
  transition: 0.3s all linear;
  max-width: 330px;
  max-height: 50px;

  #text {
    display: block;
    min-width: 200px;
    color: white;
    transition: 0.8s;
    overflow: hidden;
    //display: inline;
  }
}

.extra-button-main-style.left {
  left: 30px;
}

.extra-button-main-style.right {
  right: 30px;
}

.table-cell-width {
  max-width: 300px;
  overflow: auto
}

.first-create-data {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  max-width: 300px;
  overflow: auto
}

.cell-action {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  align-content: center;
  width: auto;
  align-self: center;

}

@media(max-width: 450px) {
  #content {
    padding-left: 3px !important;
    padding-right: 3px !important;
    padding-top: 30px !important;
  }
  #upper > div#card-row {
    padding: 5px 3px !important;
  }
  #card-row > #card.card-fix {
    height: 98px !important;
  }
  .bg-white.dashboard #second-in #upper #card-row #card0 div label, .bg-white.dashboard #second-in #upper #card-row #card div label {
    font-size: 20px;
  }
}
