.partner-page-container{
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 1000px;
    text-align: center;
    background-color: white;

}
.success-toast{
    left:calc(50% - 150px);
    padding: 8px 0;
}
.partner-foto-viewer{
    max-width: 1000px;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 50px 0;
}
.add-image-panel{
    width: 180px;
    margin: 0 auto;
    align-self: center;
    background-color: var(--global-button-color);
    color: white;
    padding: 10px 0;

}
.add-image-partner:hover{
    background-color: var(--global-hover-button-color);
    box-shadow: var(--global-button-shadow);
}
.partner-foto-container{
    min-width: 1000px;
    justify-content: start ;
    padding-left: 40px;
}
.partner-page-footer{
    font:normal normal bold 16px/1.4em 'arial black',arial-w01-black,arial-w02-black,'arial-w10 black',sans-serif;
    width: 600px;
    align-self: center;
    margin-top: 20px;
}
.bg-white{
    background-color: white;
}
.partner-page-container div ul li h3 {
    margin:0 !important;
}

.partner-page-title{
    font: normal normal normal 70px/1.4em Futura-LT-W01-Light,sans-serif;
    text-shadow:rgba(0, 0, 0, 0.4) 0px 4px 5px ;
    text-align: center;
    font-size: 35px;
    margin-top: 40px;
}
.partner-page-subtitle{
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 40px;
}
@media(max-width: 1000px){
    .partner-page-container{
        width: 90%;
    }
}
@media(max-width: 600px){
    .partner-page-footer{
        width: auto;
    }
}
.partner-panel-image{
    position: relative;
}

.partner-panel-icons{
    position: absolute;
    display: flex;
    z-index: 31;
    left:10px;
    height: 40px;
    bottom:5.5px;
    align-items: center;
    justify-content: space-evenly;
    width: 180px;
    background-color: rgba(0, 0, 0, 0.8);
    margin: 0 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.add-photo-panel{
    color: var(--global-button-color);
    cursor: pointer;
    align-self: center;
    font-size: 95px !important;
}
.add-photo-panel:hover{
    color:#fa0
}
