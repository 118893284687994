@font-face {
    font-family: "Futura-LT-W01-Book";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/cf053eae-ba1f-44f3-940c-a34b68ccbbdf.eot?#iefix") format("eot"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/8bf38806-3423-4080-b38f-d08542f7e4ac.woff2") format("woff2"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/e2b9cbeb-fa8e-41cd-8a6a-46044b29ba52.woff") format("woff"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c2a69697-4f06-4764-abd4-625031a84e31.ttf") format("truetype"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/dc423cc1-bf86-415c-bc7d-ad7dde416a34.svg#dc423cc1-bf86-415c-bc7d-ad7dde416a34") format("svg");
}
.memorial-activate-title{
    font: normal normal normal 39px/1.4em avenir-lt-w01_35-light1475496,sans-serif;
    font-family: futura-lt-w01-book,sans-serif;
    color: black;
}
.div-upload{
    position: fixed;
    height: 100%;
    z-index: 31;
    width: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.8);
    color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.label-upload{
    color: white;
    font-size: 23px;
    margin-top: 10px;
}
.obscure{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0,0,0,0.7);
}
.memorial-activate-subtitle{
    display: flex;
    flex-direction: column;
    font: normal normal normal 22px/1.4em cookie,cursive;
    font-family: futura-lt-w01-book,sans-serif;
    color: black;
}
.memorial-container-activate{
    width: 768px;
}
.terms-memorial{
    font: normal normal normal 23px/1.4em futura-lt-w01-book,sans-serif;
    font-family: futura-lt-w01-book,sans-serif;
    color: black;
}
@media (max-width: 360px) {
    .memorial-activate-subtitle,.text-container-tutorial{
        padding: 0 15px;
    }
}
@media (max-width: 810px) {
    .memorial-container{
        width: 100%;
    }
    .checkbox-left{
        text-align: start !important;
    }
}
