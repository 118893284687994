.modal-bg {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 30;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .inner {
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 20px;
    background-color: white;
    border-radius: 5px;
    box-shadow: var(--global-button-shadow);
.search-button{
  font-size:20px;
  font-family: Calibri;
  outline: none;
  border:none;
  width: 120px;
}
    .submit-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-around;
      margin-top: 20px;

      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        height: 40px;
        width: 60px;
        background-color: #ffaa00;
        cursor: pointer;
      }

      div:hover {
      box-shadow: var(--global-button-shadow);
      }
    }
  }
}


