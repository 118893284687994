.content-modify-partner{
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 31;
  left: 0;
  display: flex;
  justify-content: center;

  align-items: center;
  top: 0;
background-color: rgba(0,0,0,0.7);
  #container{
    width: 300px;
    height: auto;
    padding: 20px;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    #title{
      color: black;
      font-size: 25px;
      margin-bottom: 20px;
    }
    #titleLogo{
      overflow: hidden;
      text-overflow: ellipsis;
      background-color: var(--global-button-color);
      color: white;
      font-weight: bold;
      align-items: start;
      vertical-align: center;
      display: flex;
      padding: 10px 5px;
      text-align: left;
      justify-content: start;
      cursor: pointer;
      p{
        display: flex;
        text-align: center;
        justify-content: space-around;
        align-self: center;
        margin:0 auto;
      }
    }
    #button-modify{
      background-color: #ffaa00;
      width: 100px;
      align-self: center;
      margin-top: 20px;
      height: 40px;
      outline: none;
      border:none;
      font-family: Calibri;
      font-size: 20px;
      cursor: pointer;
      color: white;
      &:hover{
        background-color: var(--global-hover-button-color);
      }
    }

  }
}
