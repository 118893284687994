@import url('https://fonts.googleapis.com/css?family=Cookie:regular,bold,italic&subset=latin,latin-ext');

@font-face {
    font-family: "Barlow-ExtraLight";
    src: url("//fonts.gstatic.com/s/barlow/v1/51v0xj5VPw1cLYHNhfd8NPY6323mHUZFJMgTvxaG2iE.eot?#iefix") format("eot"), url("//fonts.gstatic.com/s/barlow/v1/51v0xj5VPw1cLYHNhfd8NFtXRa8TVwTICgirnJhmVJw.woff2") format("woff2"), url("//fonts.gstatic.com/s/barlow/v1/51v0xj5VPw1cLYHNhfd8ND8E0i7KZn-EPnyo3HZu7kw.woff") format("woff"), url("//fonts.gstatic.com/s/barlow/v1/51v0xj5VPw1cLYHNhfd8NKCWcynf_cDxXwCLxiixG1c.ttf") format("truetype"), url("//fonts.gstatic.com/l/font?kit=51v0xj5VPw1cLYHNhfd8NJbd9NUM7myrQQz30yPaGQ4&skey=e8c74abecf94633e&v=v1#Barlow") format("svg");
}

@font-face {
    font-family: "Futura-LT-W01-Book";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/cf053eae-ba1f-44f3-940c-a34b68ccbbdf.eot?#iefix") format("eot"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/8bf38806-3423-4080-b38f-d08542f7e4ac.woff2") format("woff2"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/e2b9cbeb-fa8e-41cd-8a6a-46044b29ba52.woff") format("woff"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c2a69697-4f06-4764-abd4-625031a84e31.ttf") format("truetype"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/dc423cc1-bf86-415c-bc7d-ad7dde416a34.svg#dc423cc1-bf86-415c-bc7d-ad7dde416a34") format("svg");
}

.text-container-tutorial {
    max-width: 750px;
    margin: 0 auto;
    background-color: var(--global-bg-inner-color);
}

.main-div {

    background-color: var(--global-bg-inner-color);
}

.video-react-big-play-button, .video-react-big-play-button-left {
    position: absolute;
    right: 0;
    top: 39%;
}

.text-how {
    margin: 0 10px;
    margin-top: 40px;
    font: normal normal normal 31px/1.4em cookie, cursive;
    font-family: Futura-LT-W01-Book, sans-serif;
    font-size: 18px;
    letter-spacing: 0.05em;
    line-height: 1.6em;
    text-align: justify;
    color: black;
}

.sign {
    margin-top: -8px;
    text-align: end;
    margin-bottom: 10px;
}

.citation {
    max-width: 261px;
    text-align: center;
    margin: auto;
    line-height: 35px;
    margin-top: 40px;

}

.title-what {
    font: normal normal normal 50px/1.4em Barlow-ExtraLight, barlow, sans-serif;
    margin-top: 25px;
}
