
.white-text{
  color: white !important;

}
.columns {
  height: 700px;

}

.login-page {
  background: linear-gradient(to right, #ffaa00, #eac74c);
  margin: 0 !important;

  .column {
    background-color: white;
    margin-top: 40px;
    align-self: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    padding: 40px;
    box-shadow: rgba(0, 0, 0, 0.4) 0 0 5px 1px;
    border-radius: 5px;

    .death-data {
      text-align: center;
      margin-top: -13px;
      margin-bottom: 0px;
      align-self: center;
    }

    p {
      width: 300px;
      margin-top: 10px;
    }

    .login-form {
      width: 320px;
      margin-bottom: 12px;

    }

    margin-bottom: 40px;

    .button.is-primary {
      color: black !important;
      border:none !important;
      background-color: var(--global-button-color) !important;
    }

    .button.is-primary:hover, .button.is-primary:focus {
      color: white !important;
      background-color: var(--global-hover-button-color) !important;
      border:none !important;
      box-shadow: rgba(0, 0, 0, 0.1) 0 3px 5px 1px;
    }

    #bottom-login {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      margin-bottom: 8px;
      justify-content: space-between;

      .activate-checkbox {
        align-content: center;
        align-items: end;
        font-style: normal;
        align-self: start;
        margin-left: -10px;
      }
    }
  }

}

#footer-login {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;

  .divider-login {
    height: 2px;
    width: 25px;
    margin: 0 9px;
    background-color: grey;
    margin-bottom: 6px;
  }
}

.field.is-clearfix {
  width: 320px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 8px;

  button {
    width: 200px;
  }
}

.mb-20 {
  margin-bottom: 20px;
}

.login-remember {
  color: black;
  font-style: normal;
}

.not-registered {
  margin-top: 20px;
}

#click-here {
  cursor: pointer;
  color: var(--global-button-color);
}

#click-here:hover {
  text-decoration: underline;
  color: #4c2f0c;
}

.recover-password-bg {
  background: linear-gradient(to right, #ffaa00, #eac74c);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 488px;

  .recover-password-form {

    padding: 20px;
    background-color: white;
    width: 360px;
    border-radius: 10px;
    box-shadow: var(--global-button-shadow);

    .text-field-reset {
      margin-bottom: 20px;
    }

    button {
      color: white !important;
      border-color: var(--global-button-color) !important;
    }

    button:hover {
      color: #fff !important;
      background-color: var(--global-hover-button-color) !important;
      border-color: var(--global-button-color) !important;
      box-shadow: 0 3px 5px 1px rgba(0, 0, 0, .1);

    }
  }
}
.login-page.columns.is-mobile.is-centered.bg-modal{
  background: rgba(0,0,0,0.8);
  height: 100% !important;
  align-items: center;
  justify-content: center;
  align-content: center;
  .media-fix{
    max-height: 420px;
  }
}
@media (max-width: 400px) {
  .login-page {
    .media-fix {
      width: 100% !important;
      padding: 40px 10px !important;
    }

    .login-form {
      width: 300px ;
    }
  }
  #bottom-login {
    display: flex;
    flex-direction: row;
    width: auto !important;
    align-items: center;
    margin-bottom: 8px;
    justify-content: center;
  }
}
