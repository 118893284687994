.search-gift-type {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.admin-gift-form-focus {
  display: none;
}

.fix-height {
  height: auto;
  min-height: auto;
}



.admin-gift-form-blur {
  margin-top: 20px;
  position: absolute;
  left: -41px;
  right: -41px;
  top: 90px;
  //width: 100%;
  height: 95%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 28;
  padding: 40px;

  label {
    color: white;
    font-size: 25px;
  }
}
@media (max-width: 440px) {
  .admin-gift-form-blur {
    left: -15px;
    right: -15px;
  }
}

@media (max-width: 325px) {
  .admin-gift-form-blur {
    left: -0px ;
    right: -0px ;
  }
}
.admin-add-gift-deceased {
  display: flex;
  flex-direction: row;
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.3);
  padding: 10px;
  border-radius: 5px;
  justify-content: space-between;

  #deceased {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  img {
    width: 150px;
  }
}
.MuiTypography-root.MuiPickersToolbarText-toolbarTxt.MuiPickersToolbarText-toolbarBtnSelected.MuiTypography-h4{
  text-transform: capitalize;
}
