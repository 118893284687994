.payment-main-modal {
  width: 500px;
  height: auto;
  min-height: 250px;
  background-color: white;
  border-radius: 5px;
  elevation: 5px;
  padding: 20px 40px;

  .header {
    font-size: 25px;
    color: black;
    font-weight: bold;
    margin-bottom: 20px;
  }

  #purchase-detail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    #purchase-description {
      text-align: start;
      width: 250px;
    }

    margin-bottom: 40px;
  }

  #collapsable-container {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px 1px;
  }
}

.success-title-fix {
  width: 100%;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 18px;
}

.flex-row-justify-between-w-full {
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 100%
}

.req label {
  font-weight: bold !important;
  color: black !important;
}

.req .Mui-error {
  font-weight: normal !important;
  color: red !important;
}

.w-full {
  width: 100%
}

.padding-x-20 {
  padding: 0 20px;
}

.p-y-10 {
  padding: 10px 0;
}

@media (max-width: 460px) {
  .payment-main-modal {
    width: auto;
    padding: 20px 25px;

    #purchase-detail {
      width: auto;

      #purchase-description {
        width: auto;
      }
    }
  }

}

.footer-modal {
  display: flex;
  flex-direction: column;
  justify-self: flex-end;
  align-self: flex-end;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-40 {
  margin-bottom: 40px;
}
