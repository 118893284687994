.contact-container{
    background-color: var(--global-bg-inner-color);
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    min-height: 710px;
}
.overflow-x-hidden{
    overflow-x: hidden;
}
.contact-main-text{
    color: /*#3C5BBA*/black;
    text-align: center;
    font: normal normal normal 27px/1.4em cookie,cursive;

    font-family: futura-lt-w01-book,sans-serif
}
.field{
    width: 280px;
}
.contact-title{
    color: var(--global-sub-title-color);
    font-weight: bold;
    font-size: 37px;
    align-self: flex-start;
    align-self: center;
}
.contact-form{
    background-color: var(--global-second-bg-inner-color);
    padding: 25px 50px;
    border: var(--global-button-color) 2px solid;
    width: 711px;
    height: auto;
    display: flex;
    flex-direction: column;
}
.bold-underline{
    font-weight: bold;
    text-decoration: underline;
}

.text-area{
    width: 100%;
    margin-top: 40px !important;
}


.field-group{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 50px;
}

.bottom-contact-submit{
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
}
.bottom{
    margin-top: 60px;
    width: 100%;
}
.contact-button{
    cursor: pointer;
    height: 78px;
    width: 270px;
    font-size: 31px;
    font: normal normal normal 50px/1.4em helvetica-w01-roman,helvetica-w02-roman,helvetica-lt-w10-roman,sans-serif;
    color: white;
    background-color: var(--global-button-color);
    border: 2px var(--global-sub-title-color) solid ;
}
.contact-button:hover {
    background-color: var(--global-hover-button-color);
    box-shadow: var(--global-button-shadow);
}

.text-area-placeholder-form{
    margin-left: -97%;
    margin-top: 8px;
}

.input-field:focus{
    outline: none;
    /*color: #ff0000;*/
    /*background-color: white;*/
    /*margin-top: -20px;*/
    /*align-self: end;*/
    /*border: 0px;*/
}
@media (max-width: 680px) {
    .field{
        width: 100%;
        margin-top: 20px !important;
    }
    .contact-form{
        width: 100%;
        height: auto;
    }
    .field-group{
        flex-direction: column;
        margin-top: 10px;
        align-items: center;
    }
    .contact-title{
        align-self: center;
    }
    .bottom-contact-submit{
        flex-direction: column;
    }
    .contact-button {
        margin-top: 20px;
    }
}
