@import url('https://fonts.googleapis.com/css?family=Cookie:regular,bold,italic&subset=latin,latin-ext');
/*https://static.parastorage.com/services/skins/2.1229.80/images/wysiwyg/core/themes/base/liftedshadow_medium.png*/
@font-face {
    font-family: "Barlow-ExtraLight";
    src: url("//fonts.gstatic.com/s/barlow/v1/51v0xj5VPw1cLYHNhfd8NPY6323mHUZFJMgTvxaG2iE.eot?#iefix") format("eot"), url("//fonts.gstatic.com/s/barlow/v1/51v0xj5VPw1cLYHNhfd8NFtXRa8TVwTICgirnJhmVJw.woff2") format("woff2"), url("//fonts.gstatic.com/s/barlow/v1/51v0xj5VPw1cLYHNhfd8ND8E0i7KZn-EPnyo3HZu7kw.woff") format("woff"), url("//fonts.gstatic.com/s/barlow/v1/51v0xj5VPw1cLYHNhfd8NKCWcynf_cDxXwCLxiixG1c.ttf") format("truetype"), url("//fonts.gstatic.com/l/font?kit=51v0xj5VPw1cLYHNhfd8NJbd9NUM7myrQQz30yPaGQ4&skey=e8c74abecf94633e&v=v1#Barlow") format("svg");
}

@font-face {
    font-family: "Avenir-LT-W01_85-Heavy1475544";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/6af9989e-235b-4c75-8c08-a83bdaef3f66.eot?#iefix") format("eot"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/d513e15e-8f35-4129-ad05-481815e52625.woff2") format("woff2"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/61bd362e-7162-46bd-b67e-28f366c4afbe.woff") format("woff"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/ccd17c6b-e7ed-4b73-b0d2-76712a4ef46b.ttf") format("truetype"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/20577853-40a7-4ada-a3fb-dd6e9392f401.svg#20577853-40a7-4ada-a3fb-dd6e9392f401") format("svg");
}

@font-face {
    font-family: "Futura-LT-W01-Book";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/cf053eae-ba1f-44f3-940c-a34b68ccbbdf.eot?#iefix") format("eot"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/8bf38806-3423-4080-b38f-d08542f7e4ac.woff2") format("woff2"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/e2b9cbeb-fa8e-41cd-8a6a-46044b29ba52.woff") format("woff"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c2a69697-4f06-4764-abd4-625031a84e31.ttf") format("truetype"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/dc423cc1-bf86-415c-bc7d-ad7dde416a34.svg#dc423cc1-bf86-415c-bc7d-ad7dde416a34") format("svg");
}

.text-container {
    max-width: 1200px;
    align-items: center;
    align-content: center;
    margin: 0 auto;
    background-color: var(--global-bg-inner-color);
    display: flex;
    flex-direction: column;
}

.dedic-search-container {
    margin-top: 20px;
    padding-top: 17px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    border-radius: 4px;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.5);
}

.dedic-search {
    width: 100%;
    display: flex;
    margin-top: 20px;
    align-items: flex-end;
    justify-content: space-between;

}

@media (max-width: 630px) {
    .dedic-search {
        flex-direction: column;
        align-items: flex-start;
        height: 100%;
    }

    .dedic-search-field {
        width: 100% !important;
        margin-bottom: 20px !important;
    }

    .dedic-search-container {
        padding-bottom: 5px;
    }
}

.card-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.main-div {

    background-color: #ffffff;
}

.mt-50 {
    margin-top: 50px;
}

.card-label {
    font: normal normal normal 23px/1.4em avenir-lt-w01_35-light1475496, sans-serif;
    font-family: open sans, sans-serif;
    color: #577083;
    text-align: start;
    margin-top: 10px;

}

.img-shadow {
    margin-top: -5px;
    position: relative;
    right: 0;
    top: 0px;
}

.add-item {
    padding: 8px;
    background-color: var(--global-button-color);
    color: black;
    cursor: pointer;
    padding-bottom: 10px;
    border-radius: 2px;
}

.add-item:hover {
    background-color: var(--global-hover-button-color);
    color: white !important;
    box-shadow: var(--global-button-shadow);
}

.price {
    color: var(--global-button-color);
    font-size: 26px;
    font: normal normal normal 24px/1.4em bree-w01-thin-oblique, sans-serif;
    font-weight: bold;
    text-align: start;
    margin-bottom: 20px;
    margin-top: 10px;
}

.second-half-card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 5px;
}

.card-label-cost {
    font: normal normal normal 31px/1.4em cookie, cursive;
    color: #577083;
    text-align: start;
    width: 100%;
    display: none;

}

.pricing {
    display: flex;
    flex-direction: column;
    align-content: flex-start;

}

.pricing-card {
    margin: 20px 5px;
    width: 237px;
    height: min-content;
    background-color: var(--global-bg-inner-color);
    box-shadow: rgba(0, 0, 0, 0.5) 0px 4px 10px 0px;
    margin-left: 20px;
    padding: 15px 5px;
}

.div-label-card {
    display: flex;
    flex-direction: row;
}

.warning {
    max-width: 82%;
    font-family: futura-lt-w01-book, sans-serif;
    font-size: 23px;
    color: black;
    font-weight: bold;
    text-decoration: underline;
    text-align: justify;
}

.inner-image {
    width: inherit;
    padding: 1px;
    max-height: 100%;
}

.inner-card {

    background-color: white;
    width: 100%;
    height: 220px;
    border: 2px solid rgba(255, 255, 255, 1);

}

.card-sub-label {
    font: normal normal normal 20px/1.4em avenir-lt-w01_35-light1475496, sans-serif;
    font-size: 20px;
    color: #577083;
    align-self: flex-start;
    display: flex;
    letter-spacing: 1.6px;
}

.desc {
    margin: auto;
    margin-top: 20px;
    font: normal normal normal 31px/1.4em cookie, cursive;
    font-family: Futura-LT-W01-Book, sans-serif;
    font-size: 18px;
    letter-spacing: 0.05em;
    line-height: 1.6em;
    text-align: center;
    color: black;
    max-width: 750px;
}

@media (max-width: 420px) {
    .card-row {
        flex-direction: column;
        width: 90% !important;
    }

    .pricing-card {
        width: 100%;
        margin: 5px 0;
        padding: 25px 25px;
    }

    .inner-card {
        height: 70%
    }

    .img-shadow {
        top: 5px
    }

    .title-price {
        font: normal normal normal 60px/1.4em Barlow-ExtraLight, barlow, sans-serif !important;
    }
}

@media (max-width: 1000px) {
    .card-row {
        flex-direction: column;
        width: 380px;
    }

    .pricing-card {
        width: 100%;
        margin: 5px 0;
        padding: 25px 25px;
    }

    .inner-card {
        height: 70%
    }

    .img-shadow {
        top: 5px
    }

    .title-price {
        font: normal normal normal 60px/1.4em Barlow-ExtraLight, barlow, sans-serif !important;
    }
}

@media (max-width: 343px) {
    .title-price {
        font: normal normal normal 54px/1.4em Barlow-ExtraLight, barlow, sans-serif !important;
    }
}

.title-price {
    font: normal normal normal 70px/1.4em Barlow-ExtraLight, barlow, sans-serif;
    margin-top: 25px;
}
