.lampade-main-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 31;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.8);

  .lampade-card{
    min-width: 350px;
    min-height: 350px;
    border-radius: 5px;
    background-color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;

    .row {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: 1px;
    }
    p {
      text-align: start;
      font-size: 13px;
    }
    div {
      margin-top: 16px;
    }

    .ttitle {
      font-size: 22px;
      font-weight: 500;
      color: var(--global-button-color);
      width: auto;
    }
    .subbtitle {
      font-size: 14px;
      width: auto;
    }

    .lampstatus {
      display: flex;
      align-items: center;
    }
    .field{
      width: 145px;
      margin-bottom: 0px;
    }
    .field label {
      color: #4a4a4a !important;
      font-size: 1rem !important;
      width: max-content !important;
    }
    .field input {
      text-align: center !important;
    }

    .inputlabel {
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    }


    .titolotabella {
      font-size: 15px;
      width: auto;
      margin-top: 15px;
    }
    .tabellalampadecollegate {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 120px;
      border: 1px solid #949494;
      overflow-y: scroll;
      width: 100%;
      margin-top: 5px;
      margin-bottom: 15px;
    }
    .lampadecollegate {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #949494;
      height: 40px;
      margin-top: 0px;
      padding: 5px;
      width: 100%;
    }
    .lampadecollegateswitch {
      align-self: center;
      transform: translateY(1px);
    }

    button{
      outline: none;
      cursor: pointer;
      border:none;
      margin-top: auto;
      background-color: #ffaa00;
      color: black;
      padding: 10px 20px;
      width: 100px;
      align-self: flex-end;
    }
    button:hover{
      background-color: var(--global-hover-button-color);
      box-shadow: var(--global-button-shadow);
    }
  }
}
.check-search {
  margin-top: 20px;
  .MuiCheckbox-root {
    color: var(--global-button-color) !important;
  }
}
