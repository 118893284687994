@font-face {
    font-family: "Futura-LT-W01-Light";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/2b40e918-d269-4fd9-a572-19f5fec7cd7f.eot?#iefix") format("eot"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/26091050-06ef-4fd5-b199-21b27c0ed85e.woff2") format("woff2"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/cca525a8-ad89-43ae-aced-bcb49fb271dc.woff") format("woff"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/88cc7a39-1834-4468-936a-f3c25b5d81a1.ttf") format("truetype"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/14d6bf5d-15f1-4794-b18e-c03fb9a5187e.svg#14d6bf5d-15f1-4794-b18e-c03fb9a5187e") format("svg");
}

@font-face {
    font-family: "Cookie";
    src: local("Cookie-Regular"), url("https://fonts.gstatic.com/s/cookie/v12/syky-y18lb0tSbf9kgqS.woff2") format("woff2");
    font-style: normal;
    font-weight: 400;
    unicode-range: U+0-FF, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: "Bree-W01-Thin-Oblique";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/4e33bf74-813a-4818-8313-6ea9039db056.eot?#iefix") format("eot"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/ceb3b4a3-0083-44ae-95cb-e362f95cc91b.woff2") format("woff2"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/4d716cea-5ba0-437a-b5a8-89ad159ea2be.woff") format("woff"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c458fc09-c8dd-4423-9767-e3e27082f155.ttf") format("truetype"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/85ffb31e-78ee-4e21-83d8-4313269135a9.svg#85ffb31e-78ee-4e21-83d8-4313269135a9") format("svg");
}

.light-box {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2000;
}
/*@media ma {

}
.memorial-video-thumbnail*/
.memorial-video-thumbnail {
    height: 200px;
    width: 200px;
    object-fit: cover;
    cursor: pointer;
    transition: linear;
    transition-duration: 0.1s;
}

.img-contained {
    object-fit: contain !important;
}

.memorial-person-image {
    padding: 5px;
    height: 200px;
    width: 200px;
    object-fit: contain;
    cursor: pointer;
    transition: linear;
    transition-duration: 0.1s;
}
@media (min-width: 600px) {
    .memorial-person-image:hover {
        transform: scale(1.5);

    }

    .memorial-video-thumbnail:hover {
        transform: scale(1.5);
    }

}

.memorial-page-marble {
    background-image: url("../../assets/bgmarble.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 40px;
    box-shadow: 2px 2px 8px;
}

.memorial-page-container {

    width: 1000px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-self: center;
}

.memorial-first-label, .memorial-first-label p, .memorial-first-label div, .memorial-first-label label, .memorial-first-label span {
    font: normal normal normal 29px/1.4em Futura-lt-w01-light, sans-serif;
    font-family: futura-lt-w01-light, sans-serif;
    text-shadow: rgba(0, 0, 0, 0.4) 0px 4px 5px;
    max-width: 1000px;
    margin: 8px auto;
}

@media (max-width: 600px) {
    .memorial-name-label {
        font-size: 26px !important;
    }

    .memorial-name-label.font-size-25 {
        font-size: 22px !important;
        margin: 12px 0;
    }

    .memorial-first-label, .memorial-first-label div, .memorial-first-label label, .memorial-first-label p, .memorial-first-label span {
        margin-top: 5px !important;
    }

    .memorial-first-label, .memorial-first-label p, .memorial-first-label div, .memorial-first-label label, .memorial-first-label span {
        font: normal normal normal 22px/1.4em Futura-lt-w01-light, sans-serif !important;

    }

}


li {
    text-align: left;
}

.width100 {
    width: 100%;
    max-width: unset;
    display: flex;
    align-self: center;
}

.memorial-buy-header {
    font: normal normal normal 46px/1.4em avenir-lt-w01_35-light1475496, sans-serif;
    color: #666666;
}

.memorial-page-photo {
    object-fit: cover;
    background-size: cover;
    height: 668px;
    min-width: 540px;
    width: 540px;
    margin: auto;
    border-radius: 50%;
    box-shadow: #7c7c7c -10px 10px 10px 0px;
    border: 5px solid;
}

.memorial-page-main-div {
    height: auto;
    display: flex;
    background-color: white;
    flex-direction: column;
}

.memorial-data-div {
    margin-left: 80px;
}

.memorial-anagraphics {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.memorial-name-label {
    font: normal normal normal 35px/1.4em barlow-extralight, barlow, sans-serif;
    text-shadow: rgba(0, 0, 0, 0.4) 0px 4px 5px;

}

.font-size-25 {
    font-size: 25px;
    font-weight: 700;
}

.font-size-19 {
    font-size: 19px;
}

.memorial-azienda {
    font: normal normal normal 15px/1.4em bree-w01-thin-oblique, sans-serif;
    text-shadow: rgba(0, 0, 0, 0.4) 0px 4px 5px;
    font-weight: bold;
    text-decoration: underline;
    text-align: center;
    color: black;
}

.memorial-azienda:hover {
    color: #393939 !important;
}

/*with book*/
.memorial-book-div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.memorial-book {
    width: 155px;
    height: 101px;
}

.memorial-book-title {
    text-decoration: underline;
    font: normal normal normal 31px/1.4em cookie, cursive;
    color: black;
}

.memorial-page-photo-resized {
    object-fit: cover;
    background-size: cover;
    height: 485px;
    width: 336px;
    align-self: center;
    margin-left: 20%;
    border-radius: 50%;
    box-shadow: #7c7c7c -10px 10px 10px 0px;

    border: 5px solid;
}

.memorial-page-lamp-photo {
    object-fit: cover;
    background-size: cover;
    height: 167px;
    width: 72px;
    align-self: center;
    margin-left: 20%;
}

.memorial-page-container-resized {
    width: 970px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-self: center;
}


.memorial-who {
    margin: 40px 0;

    font: normal normal normal 26px/1.4em avenir-lt-w01_35-light1475496, sans-serif;
}

.memorial-bio-div {
    display: flex;
    flex-direction: column;
}

.memorial-bio, .memorial-bio div, .memorial-bio p, .memorial-bio label {
    font: normal normal normal 31px/1.4em cookie, cursive;
    text-align: justify;
}

.memorial-dedics {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
}

.memorial-dedics-header {
    width: 100%;
    height: 60px;
    background-color: #6d7899;
    margin-top: 50px;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.memorial-remember-label {
    font: normal normal normal 26px/1.4em avenir-lt-w01_35-light1475496, sans-serif;
    color: white;
}

.memorial-dedic-content {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
}

.memorial-dedic-date {
    text-align: start;
    color: #999999;
    font: normal normal normal 25px/1.4em cookie, cursive;
}

.memorial-dedic-image {
    width: 300px;
    align-self: center;
}

.text-end {
    text-align: end;
}

.divider {
    align-self: center;
    width: 500px;
    border-bottom: 4px solid #dbdbdb;
}

.memorial-dedic-uploaded-image {
    width: max-content;
    max-width: 600px;
    align-self: center;
    margin-bottom: 40px;
}

@media (max-width: 1000px) {
    .jss1 {
        font-size: 47px !important;
    }

    .memorial-page-container {
        width: auto;
        padding: 0 30px;
    }
}

@media (max-width: 940px) {
    .memorial-page-container, .memorial-page-container-resized {
        flex-direction: column;
        align-self: center;
        width: auto;
        padding: 0 10px !important;
    }

    .memorial-page-photo, .memorial-page-photo-resized, .memorial-data-div {
        margin: 0;
    }
}

@media (max-width: 500px) {
    .divider {
        width: 300px;
    }

    .memorial-page-container {
        padding: 0 15px !important;

    }

    .width700 {
        width: auto !important;
    }
}

@media (max-width: 700px) {
    .memorial-dedic-uploaded-image {
        max-width: 100% !important;
    }

    .width700 {
        width: auto !important;
    }
}

@media (max-width: 570px) {
    .memorial-page-photo {
        min-width: 400px;
        height: 560px;
    }

}

@media (max-width: 400px) {
    .memorial-page-photo-resized, .memorial-page-photo {
        width: 300px;
        min-width: 300px;
        height: 390px;
    }

}
