.bg-modal{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.8);
  z-index: 3001;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  .main-container{
    padding: 20px;
    width: 550px;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    #search-user{
      display: flex;
      flex-direction: column;
      align-items: start;
      margin-left: 12px;
      margin-right: 12px;
      label{
        padding: 8px;
        width: 92%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;
      }
      .userSelected{
        background-color: #ffaa00;
      }
    }
    #search {
      align-content: center;
      display: flex;
      align-items: center;
      justify-content: space-around;
      #field {
        width: 420px;
      }
    }
    #button-field {
      width: 100%;
      margin-top: 20px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-around;
      align-items: flex-end;
      button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        height: 40px;
        font-size: 15px;
        width: 60px;
        outline: none;
        border:none;
        background-color: #fa0;
        cursor: pointer;
      }
      button:hover{
        background-color: var(--global-button-color);
        box-shadow: var(--global-button-shadow);
      }
    }
  }
}

.search-header{
  padding: 8px;
  width: 92%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  margin-left: 12px;
  padding-right: 48px;
  font-weight: bold;
}
@media (max-width: 580px) {
  width: 100%;
}
