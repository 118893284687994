.reset-password-form.modify {
  width: 700px !important;

  .text-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .login-form {
      width: 292px;
    }
  }
}
.MuiCollapse-container.MuiCollapse-entered #toCenterFix .activate-radio-title{
  margin-top: 0;
}
.MuiCollapse-container.MuiCollapse-entered #toCenterFix > div{
  margin-bottom: 12px;
}
@media (max-width: 750px) {
  .MuiCollapse-container.MuiCollapse-entered #preferences-panel{
    width: calc(100% - 50px);
    margin: 0 auto;
  }
  .reset-password-form.modify {
    width: 100%;
    padding: 20px;

    .text-row {
      flex-direction: column;
      width: 100%;

      .login-form {
        width: 100%;
      }
    }
  }

}

.notification-main-container {
  align-self: center;
  width: 700px;

  #title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .notification-container {
    padding: 20px;
    width: 700px;
    height: auto;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    margin: 20px;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      outline: none;
      border: none;
      border-radius: 2px;
      width: 90px;
      margin-top: 20px;
      height: 35px;
      color: black;
      background-color: var(--global-button-color);
      cursor: pointer;
    }

    button:hover {
      box-shadow: var(--global-button-shadow);
    }
  }

  .address-notification {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 0;

    .text-field-address {
      width: 22%;
    }
  }
}

@media(max-width: 700px) {

  .address-notification .first-div .MuiSvgIcon-root {
    margin-right: 24px !important;
  }
  .address-notification .first-div .MuiSvgIcon-root.MuiSelect-icon{
    margin-right: 0 !important;
  }
  .notification-main-container {
    width: 100%;
  }
  .notification-container {
    padding: 20px 0 !important;
    margin: 20px 0 !important;
    width: 100% !important;
  }
  .address-notification {
    //width: auto !important;
    flex-direction: column !important;

    .first-div {
      width: 100% !important;
    }

    .text-field-address, .field-region {
      margin: 10px 0 !important;
      margin-left: 48px;
      width: calc(100% - 60px) !important;
    }

    .text-field-address {
      margin-left: 48px !important;
    }

  }
  .second-in .text-field-address, .field-region {
    width: calc(100% - 90px) !important;
  }
}

.bg-modal {
  #info-container {
    align-self: center;
    //width: 700px;
    max-width: 100%;
    height: auto;
    background-color: white;
    border-radius: 5px;
    padding: 20px;

    p {
      width: 100%;
      margin: 0;
      margin-bottom: 10px;
    }

    button {
      outline: none;
      border: none;
      border-radius: 2px;
      width: 90px;
      margin-top: 20px;
      height: 35px;
      color: black;
      background-color: var(--global-button-color);
      cursor: pointer;
    }

    button:hover {
      box-shadow: var(--global-button-shadow);
    }
  }
}
