.reset-password-form{
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 30px 47px;
  margin: 20px;
  box-shadow: rgba(0,0,0,0.1) 0 0 1px 2px;
  border-radius: 5px;
  .death-data{
    color: #ffaa00;
    margin: 0px auto 10px auto ;
  }
  p{
    margin-bottom: 20px;
  }
  .login-form{

    margin-bottom: 20px;
  }
  .field{
    width: 100% !important;
    .button{
      border-color: var(--global-button-color) !important;
      color: black !important;
    }
    .button:hover,.button:focus{
      background-color: var(--global-button-color) !important;
      color: white!important;
      box-shadow: var(--global-button-shadow);
    }
  }
}
@media (max-width: 446px) {
  .reset-password-form{
    width: 100%;
    padding: 20px;
  }
}
@media (max-width: 360px) {
  .reset-password-form{
    width: 100%;
    padding: 10px;
    margin: 0;
    border-radius: 0;
    box-shadow: none;
  }
}
.orange-button{
  border-color: var(--global-button-color) !important;
  color: black !important;
}
.orange-button:hover,.orange-button:focus{
  background-color: var(--global-button-color) !important;
  color: white!important;
  box-shadow: var(--global-button-shadow);
}
