@font-face {
    font-family: "Basic";
    src: local("Basic Regular"), local("Basic-Regular"), url("https://fonts.gstatic.com/s/basic/v10/xfu_0WLxV2_XKTNw6FE.woff2") format("woff2");
    font-style: normal;
    font-weight: 400;
    unicode-range: U+0-FF, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.terms-container{
    background-color: var(--global-bg-inner-color);
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}
.terms-inner-container{
    width: 75%;
    max-width: 900px;
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: column;
    font-weight: 500;
    font-family: basic,sans-serif;
    color: black;
}
.terms-owner{
    text-align: left;
    align-self: flex-start;
}
.text-center{
    text-align: center;
}
.text-justify{
    text-align: justify;
    align-self: flex-start;
}
@media (max-width: 1220px) {
    .terms-inner-container {
        width: 90%;
    }
}
