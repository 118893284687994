@import url('https://fonts.googleapis.com/css?family=Oswald:regular,bold,italic&subset=latin,latin-ext');

@font-face {
    font-family: 'Shelley Script LT Std';
    src: url('./assets/font/ShelleyLTStd-Script.eot');
    src: url('./assets/font/ShelleyLTStd-Script.eot?#iefix') format('embedded-opentype'),
    url('./assets/font/ShelleyLTStd-Script.woff2') format('woff2'),
    url('./assets/font/ShelleyLTStd-Script.woff') format('woff'),
    url('./assets/font/ShelleyLTStd-Script.ttf') format('truetype'),
    url('./assets/font/ShelleyLTStd-Script.svg#ShelleyLTStd-Script') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

.text-manifest {
    font-size: 22px;
    max-width: 600px;
}

.req input, .req .MuiSelect-select {
    background: #0003;
}

.logo-font {
    font-family: 'Shelley Script LT Std';
    font-size: 72px;
    font-weight: bold;
    display: flex;
    color: white;
}

@media (max-width: 650px) {
    .logo-font {
        font-size: 52px;
        line-height: 90px;
    }
}

@media (max-width: 480px) {
    .memorial-name-label {
        max-width: calc(100% - 133px);
        margin: auto;
    }

    .memorial-name-label.noMax {
        max-width: unset;
    }

    .logo-font {
        font-size: 40px;
        line-height: 90px;
    }
}

@media (max-width: 380px) {
    .logo-font {
        font-size: 40px;
        line-height: 90px;
        margin-left: -12%;
    }
}

@media (max-width: 350px) {
    .logo-font {
        font-size: 38px;
        line-height: 90px;
        margin-left: -6%;
        margin-top: -9px;
    }
}

:root {
    --global-bg-inner-color: white;
    --global-second-bg-inner-color: /*b5e0e9*/ white;
    --global-button-color: #fa0;
    --global-title-color: /*#283D7C*/ #e1802b;
    --global-sub-title-color: /*1A2DF3*/ #e1802b;
    --gloabl-nav-bar-color: black;
    --global-hover-button-color: #efad26;
    --global-button-shadow: rgba(0, 0, 0, 0.2) 2px 1px 4px 1px;
}

.App {
    text-align: center;
}

.safari-fix {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    align-self: flex-start;
    justify-self: flex-start;
}

.no-register-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: white;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 20px;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.8);
}

.white-text {
    color: white !important;
}

.obituary-note-extra {
    color: #4a4a4a !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    margin-bottom: 0 !important;
}

.no-register-overlay > a {
    color: white !important;
    font-size: 24px;
}

.photo-a4-pc {
    width: auto !important;
    height: 100% !important;
    max-width: 100%;
    align-self: center;
}

.photo-a4-mobile {
    width: 100% !important;
    height: auto !important;
    max-height: 100%;
    align-self: center;
}

.MuiTablePagination-toolbar {
    max-width: 800px;
}

.file-upload .pointer {
    position: relative;
}

.mb-12 {
    margin-bottom: 12px;
}

.pointer-label {
    margin-top: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 26px;
    max-width: 100%;
    position: relative;
}

.remove-photo {
    position: absolute;
    top: 19px;
    color: red;
    left: 0;
    cursor: pointer;
    background: white;

}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.navbar-item {
    color: white;
    font: normal normal 300 15px/1.4em oswald-extralight, oswald, sans-serif;
    background-color: var(--gloabl-nav-bar-color);
    margin-left: 10px;
    outline: none !important;
}

.navbar-item2 {
    color: #ffaa00 !important;
    font: normal normal 300 15px/1.4em oswald-extralight, oswald, sans-serif;
    background-color: var(--gloabl-nav-bar-color);
    margin-left: 10px;
    outline: none !important;
}

.navbar-item:hover {
    background-color: transparent !important;
    color: var(--global-button-color) !important;
}

.navbar-item:focus {
    background-color: transparent !important;
    color: var(--global-button-color) !important;
}

.button.is-primary {
    color: black !important;
    border: none !important;
    background-color: var(--global-button-color) !important;
}

.button.is-primary:hover, .button.is-primary:focus {
    color: white !important;
    background-color: var(--global-hover-button-color) !important;
    border: none !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0 3px 5px 1px;
}

.title-div {
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-burger {
    margin-left: 10px !important;
}

.recaptcha {
}

.title {
    width: 450px;
    margin: 15px !important;
}

.drawer-inner-component {
    height: 60px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.drawer-inner-component:active {
    background-color: var(--global-button-color);
}

.navbar-menu {
    background-color: transparent !important;
    padding: 0 !important;
    box-shadow: none !important;
}

.drawerComponent {
    padding: 0 20px;
    color: white;
    font-size: 18px;
    font-weight: bold;
    font-family: Avenir-LT-W01_35-Light1475496, sans-serif;

}

.burger {
    position: absolute !important;
    margin-left: auto !important;
    right: 5px !important;
    top: 5px !important;
    z-index: 700;
    color: white !important;
    width: 50px !important;
    height: 50px !important;
    font-size: 15px;

}

a:hover {
    color: #cecece !important;
}

#recupera-account .MuiSwitch-track, #recupera-account .MuiSwitch-thumb {
    background-color: var(--global-hover-button-color);
}

.CookieConsent {
    z-index: 999999;
}

.mt-20 {
    margin-top: 20px;
}

.mt-16 {
    margin-top: 16px;
}

.navbar-menu {
    padding: 10px !important;
    max-height: 40px;
}

.navbar-brand, .navbar-menu {
    justify-content: center;
}

.MuiFilledInput-root {
    background-color: white;
}

.vertical-divider {
    height: 90%;
    align-self: center;
    width: 2px;
    background-color: lightgray;
    margin: 0 20px;
}

.App-link {
    width: 100%;
    color: #61dafb;
    background-color: var(--gloabl-nav-bar-color);
}

.w-100perc {
    width: 100% !important;
}

.w-50 {
    width: 80px !important;
}

.ml-20 {
    margin-left: 20px !important;
}

.navbar {
    width: 100% !important;
}

@media screen and (min-width: 1024px) {


    .container {
        width: 100% !important;
        max-width: 100% !important;
    }
}

@media (max-width: 1100px) {
    #subtitle-logo {
        text-align: left !important;
        font-size: 20px !important;
    }

    #subtitle-logo2 {
        color: #fa0;
        position: absolute;
        right: 0;
        bottom: -24px;
        text-transform: capitalize !important;
        display: block !important;
        text-align: right !important;
        font-size: 18px !important;
    }

    #logo {
        position: relative;
    }

    .title-div {
        padding-bottom: 30px;
    }

    .title {
        width: 419px;
    }

    .burger {
        position: absolute !important;
        margin-left: auto;
        right: 5px !important;
        top: 27px !important;
        width: 13% !important;
    }
}

.cookie {
    margin: 0 40px;
    border-start-start-radius: 10px;
    border-start-end-radius: 10px;
    width: calc(100% - 80px) !important;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07),
    0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07),
    0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07),
    0 32px 64px rgba(0, 0, 0, 0.07);
}

@media (max-width: 600px) {
    .cookie {
        margin: 0;
        width: 100% !important;
    }
}

.h-full {
    height: 100%;
}

.bg-blue {
    background: blue;
}

@media (max-width: 550px) {
    #subtitle-logo2 {
        font-size: 16px !important;
    }

    .title {
        width: 300px;
        margin-left: -18px !important;
    }

    .burger {
        position: absolute !important;
        margin-left: auto;
        right: 5px !important;
        top: 18px !important;

    }

    .paginator-fix {
        max-height: 6px;
    }

    .search-box-div {
        margin-top: 0 !important;
    }

    .divider2 {
        max-height: 50px;
    }

    .absolute-book {
        bottom: 13px !important;
    }

    .inner-search > .result-img {
        max-width: 110px !important;
        max-height: 77px;
        min-width: 110px !important;
        min-height: 77px;
        margin-left: 10px !important;
    }

    .inner-search > .result-img.oval {
        max-height: 150px;
        min-height: 142px;
        margin: 8px !important;
    }

    .search-result-data {
        margin-left: 20px;
    }

    .subtitle > label {
        padding-top: 7px;
    }
}

.paginator-fix {
    max-height: 6px;
}

.search-box-div {
    margin-top: 0 !important;
}

@media (min-width: 920px) {
    div.search-result {
        width: 910px;
    }
}

.result-img {
    max-width: 170px;
    min-width: 170px;
    margin-left: 8px;
}

@media (max-width: 415px) {
    #subtitle-logo2 {
        font-size: 15px !important;
    }

    .Partner-row-fields.toColumn {
        flex-direction: column !important;
    }

    .partner-field.small.minField100 {
        margin-left: 0;
    }

    #subtitle-logo {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        font-size: 15px !important;
        margin-top: -30px !important;
        padding-right: 30px !important;
    }

    .fix-name {
        margin-left: -300px;
    }

    .title {
        width: 250px;
    }

    .burger {
        position: absolute !important;
        margin-left: auto;
        right: 5px !important;
        top: 12px !important;

    }
}

i.text-info {
    margin-top: 0;
}

.navbar.container.z1 {
    padding: 0;
    min-width: 100% !important;
}

ul.navbar-nav {
    font: normal normal normal 31px/1.4em Arial, cursive !important;
}

@media (max-width: 460px) {
    .memorial-book-div.mobile.scale075 {
        margin-top: 5px !important;
    }
}

@media (max-width: 346px) {
    #subtitle-logo {
        font-size: 13px !important;
        margin-top: -35px !important;
        padding-right: 23px !important;
    }

    .title {
        width: 230px;
        margin-left: -10px !important;
        margin-bottom: 30px !important;
    }


}

/*}*/
.main-container {
    /*place-items: center;*/
    display: flex;
    flex-direction: column;
    /* flex: 1;*/
    /* width: 100em;*/
    background-color: var(--global-bg-inner-color);

}

#logo {
    display: flex;
    flex-direction: column;
}

#subtitle-logo {
    text-align: right;
    padding-right: 40px;
    margin-top: -37px;
    offset-rotate: auto;
    font-family: Cookie, sans-serif;
    color: #ffaa00;
    font-size: 23px;
}

.PhoneInputInput2 {
    flex: 1 1;
    min-width: 0;
    border-top-style: none;
    color: rgba(0, 0, 0, 1);
    border-right-style: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.54);
    border-left-style: none;
    transition: color 0.2s;
    font-size: 1rem;
    width: calc(100% - 80px);
    outline: none;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.registration #phone-label {
    position: absolute;
    left: 47px;
    padding: 2px;
    top: -7px;
    background: white;
    transition: .2s;

}

#phone-label.orange {
    color: #ffaa00 !important;

}

.memorial-page-main-div .reg-phone:focus, .registration .reg-phone:focus {
    border: 2px solid #fa0 !important;

}

.PhoneInputInput2:focus {
    border-bottom: 2px solid #fa0 !important;
}

.memorial-page-main-div .reg-phone.force-red, memorial-page-main-div .reg-phone.orange.force-red, .reg-phone.force-red, #phone-label.force-red {
    border-color: red !important;
    color: red !important;
}

.PhoneInputInput2:hover {
    border-bottom: 2px solid black;
}

#phone-label {
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 0.75rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
    position: absolute;
    top: -16px;
    left: 43px;
}

.grecaptcha-badge {
    z-index: 998;
}

#cf {
    text-transform: uppercase
}

#phone-error {
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    color: red;
    float: left;
}

.memorial-page-main-div .reg-phone .PhoneInputInput2, .registration .reg-phone .PhoneInputInput2 {
    border-color: #fff !important;
    border-width: 0 !important;
    margin-bottom: 1px !important;
    margin-top: 0px !important;
    border-bottom: none !important;
    border: none;
    height: calc(100% - 1px);
}

.registration .reg-phone, .memorial-page-main-div .reg-phone {
    margin-left: 37px;
    align-items: center;
    display: flex;
    flex-direction: row;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    height: 60px;
    margin-top: 0;
}

.mt-20impo {
    margin-top: 20px !important;
}

.registration .reg-phone:hover, .memorial-page-main-div .reg-phone:hover {
    border: 1px solid #000;
}

.registration .reg-phone.orange, .registration .reg-phone.orange:hover, .memorial-page-main-div .reg-phone.orange:hover, .memorial-page-main-div .reg-phone.orange {
    border-color: #ffaa00 !important;
    border-width: 2px !important;
}

@media (min-width: 941px) {
    .mobile {
        display: none !important;
    }

}

.mb-20 {
    margin-bottom: 20px;
}

@media (max-width: 600px) {
    #toCenterFix {
        width: 225px;
    }

    #toCenterFix .field.is-clearfix {
        width: 100%
    }

    .activate-form-div {
        max-width: calc(99% + 60px) !important;
    }
}

@media (max-width: 450px) {
    .memorial-page-photo, .memorial-page-photo-resized {
        width: 225px !important;
        min-width: 225px !important;
        height: 310px !important;
        margin-bottom: 8px !important;
    }
}

@media (max-width: 940px) {
    .grecaptcha-badge {
        display: none !important;
    }

    .memorial-name-label.font-size-25 {
        margin-bottom: 20px;
    }

    .memorial-first-label, .memorial-first-label p, .memorial-first-label div, .memorial-first-label label, .memorial-first-label span {
        margin-top: 10px !important;
    }

    .memorial-page-photo, .memorial-page-photo-resized {
        width: 233px;
        min-width: 233px;
        height: 320px;
    }

    .memorial-page-marble {
        padding: 20px !important;
    }

    .memorial-name-label.font-size-19.cimitery {
        font-size: 23px !important;
    }

    .desktop {
        display: none !important;
    }

    .memorial-book-div.mobile {
        display: flex !important;
        flex-direction: column-reverse;
    }

    .memorial-book-div.mobile img {
        width: 100px;
        height: auto;
    }

    .memorial-book-div.mobile label {
        font-size: 20px;
    }
}

.MuiButton-containedPrimary:hover {
    background-color: var(--global-hover-button-color) !important;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.modal-button {
    outline: none;
    border: none;
    border-radius: 2px;
    width: 90px;
    margin-top: 20px;
    height: 35px;
    color: #fff;
    background-color: var(--global-button-color);
    cursor: pointer;
}

.modal-button:hover {
    box-shadow: var(--global-button-shadow);
}

.text-row-props {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: baseline !important;
    margin: auto 0 !important;
    height: auto !important;
}

.row-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.qrchip {
    float: right;
    background-color: darkblue;
    color: white;
    width: 120px;
    border-radius: 21px;
    padding-bottom: 1px;
    position: absolute;
    right: 0;
    top: 11px;
}

.memorial-page-main-div .PhoneInputCountry, .registration .PhoneInputCountry {
    top: 21px;
}

.PhoneInputCountry {
    position: absolute;
    top: 5px;
}

.MuiMenuItem-root {
    white-space: normal !important;
    border-top: 1px solid lightgrey !important;
}

/*.no-drop-icon .MuiSelect-iconOutlined,*/
.display-none {
    display: none !important;
}

.registration .label-country, .memorial-page-main-div .label-country {
    margin-top: -1px;
}

.label-country {
    font-size: 1rem;
    outline: none;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    color: black;
    margin-left: 20px;
}

.scale075 {
    transform: scale(0.75);
}

@media (max-height: 670px) {
    .memorial-page-photo, .memorial-page-photo-resized {
        width: 222px !important;
        min-width: 170px !important;
        height: 320px !important;
        margin-left: 23%;
    }

    .memorial-page-marble {
        padding: 20px;
        padding-bottom: 5px;
    }

    .memorial-book {
        width: 100px;
        height: auto;
    }
}

@media (max-width: 600px) {
    .toMobile.text-row-props {
        flex-direction: column !important;
        align-items: center !important;
    }
}

@media (max-width: 340px) {
    .memorial-book-div.mobile.scale075 {
        transform: scale(0.64) !important;
    }
}

.result-img.oval {
    width: 105px;
    min-width: 105px;
    border-radius: 50%;
    margin: 0 36.5px;

}

.modalInputCard {
    width: 300px;
    background: white;
    padding: 12px;
    justify-content: space-between;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.readMore {
    color: var(--global-title-color);
    background-color: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.dm-card {
    display: flex;
    padding: 12px;
    justify-content: space-between;
    width: 300px;
    height: 80px;
    background: white;
    position: relative;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.2s;
}

.pulse-anim {
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        filter: drop-shadow(0 0 0 rgba(0, 0, 0, 1))
    }

    70% {
        transform: scale(1.05);
        filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0))
    }

    100% {
        transform: scale(0.95);
        filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0))

    }
}

.dm-card-text {
    font-size: 13px !important;
    padding: 8px 16px;
}

@media (max-width: 520px) {
    .dm-card {
        width: auto;
    }

    .text-manifest {
        font-size: 13px;
    }

    .dm-card-text {
        padding: 0 19px 0 3px;

    }
}

.partnerpanel-button {
    position: absolute !important;
    right: 30px !important;
    top: 17px !important;
    padding: 10px 20px !important;
    width: auto;
    border: 0 !important;
    font-family: 'Calibri' !important;
    font-size: 20px !important;
    display: flex !important;
    align-items: center !important;
}

.textCenterChild textarea {
    text-align: center !important;
}

.partnerpanel-button.left {
    right: unset;
    width: max-content !important;
    left: 30px !important;
}

@media (max-width: 730px) {
    .partnerpanel-button.left.toRight {
        width: calc(100% - 10px) !important;
        right: 5px !important;
        top: 155px !important;
        bottom: unset !important;
        left: 5px !important;
    }
    .partnerpanel-button.toRight {
        width: calc(100% - 10px) !important;
        right: 5px !important;
        top: 155px !important;
        bottom: unset !important;
        left: 5px !important;
    }
    .partnerpanel-button.btn-fix{
        width: calc(100% - 10px) !important;
        right: 5px !important;
        left: 5px !important;
    }
}

@media (max-width: 430px) {
    .dm-card {
        width: auto;
        padding: 6px;
        height: 60px;
        align-items: center;
    }

    .dm-card-logo {
        width: 46px;
        height: 46px !important;
    }

    .dm-card-text {
        font-size: 11px !important;
    }

    .dm-card-info {
        right: 6px !important;
        top: 6px !important;
    }

    .paginator-arrow {
        font-size: 14px !important
    }

    .home-background.no-x-auto .memorial-book-back {
        font-size: 40px !important;
    }
}

.padding-child-5 input {
    padding: 5px !important;
}

.clear-button {
    background: #f0f0f0 !important;
    box-shadow: var(--global-button-shadow);
    border: 1px solid #ffa5a5 !important;
}

.refresh-button {
    background: #f0f0f0 !important;
    box-shadow: var(--global-button-shadow);
    border: 1px solid var(--global-button-shadow) !important;
}

.req .MuiFormHelperText-root.Mui-error {
    position: absolute;
    bottom: -20px;
    min-width: max-content;
    left: 0;
}

.manifest-button {
    background: var(--global-button-color);
    width: 300px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    align-self: center;
    margin-top: 12px;
    font-size: 15px;
    cursor: pointer;
    color: black;
    font-weight: bold;
}

.manifest-button:hover {
    color: white !important;
    box-shadow: var(--global-button-shadow);
}

.manifest {
    width: 180px;
    height: auto;
    transition: 0.2s;
    margin-right: 16px;
    cursor: pointer;
}

.manifest:hover {
    transform: scale(1.2);
}

.home-background.no-x-auto .memorial-book-paginator {
    min-height: 75px !important;
}

.radius-16 {
    border-radius: 16px !important;
}

.modalInputCard .label-country {
    margin-left: 40px !important;
}

.pp-header {
    font-size: 20px;
}

.success-pp {
    background: white;
    border-radius: 16px;
    padding: 5px 16px;
    display: flex;
    flex-direction: column;
    min-height: 130px;
    justify-content: space-between;
    align-items: center;
}

.button-send-card {
    align-self: flex-end;
    font-size: 12px;
    height: auto;
    width: auto;
    padding: 8px;
    min-width: unset;
    font-weight: inherit;
    margin: 0 !important;
}

.isFlexRow {
    display: flex;
    flex-direction: row;
}

.photod-style {
    max-height: 300px;
    margin: 20px auto auto;
    max-width: 208px;
    min-height: 300px;
    min-width: 200px;
}

.disable {
    color: black !important;
    background-color: grey !important;
    box-shadow: none !important;
    cursor: inherit !important;
}

@media (max-width: 370px) {
    .dm-card-logo {
        display: none;
    }

    .paginator-arrow {
        font-size: 13px !important
    }

    .home-background.no-x-auto .memorial-book-back {
        font-size: 30px !important;
    }
}

.dm-card:hover {
    transform: scale(1.07);
}

.readMore:hover {
    color: var(--global-button-color);

}


.memorial-book-form-fields > .MuiCollapse-wrapper {
    width: 100%;
}

.labelDate {
    height: auto !important;
}

@media (max-width: 730px) {
    .panel-button-resize {
        top: 80px !important;
        bottom: unset !important;
    }
}

.wizard {
    z-index: 1;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 70px;
    padding-bottom: 12px;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.8);

}
.zindex2{
    z-index: 2;
}
.wizard > div {
    position: absolute;
}

@media (max-width: 970px) {
    .wizard {
        align-items: baseline;
        padding-right: 60px;
        padding-top: 12px;
    }
}


@media (max-width: 960px) {
    .wizard > div {
        top: auto;
        bottom: 20px;
        padding-right: 60px;
        height: 45px;
    }
}

@media (max-width: 960px) {
    .wizard > div {
        top: 8%;
        padding-right: 60px;
        height: 45px;
    }
}

.bold-underline{
    font-weight: bold;
    text-decoration: underline;
}
@media (max-width: 600px) {
    .wizard > div {
        top: 17%;
        padding-right: 75px;
        left: 15px;
        height: 45px;
    }
}
