@font-face {
    font-family: "Futura-LT-W01-Light";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/2b40e918-d269-4fd9-a572-19f5fec7cd7f.eot?#iefix") format("eot"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/26091050-06ef-4fd5-b199-21b27c0ed85e.woff2") format("woff2"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/cca525a8-ad89-43ae-aced-bcb49fb271dc.woff") format("woff"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/88cc7a39-1834-4468-936a-f3c25b5d81a1.ttf") format("truetype"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/14d6bf5d-15f1-4794-b18e-c03fb9a5187e.svg#14d6bf5d-15f1-4794-b18e-c03fb9a5187e") format("svg");
}

@font-face {
    font-family: "Cookie";
    src: local("Cookie-Regular"), url("https://fonts.gstatic.com/s/cookie/v12/syky-y18lb0tSbf9kgqS.woff2") format("woff2");
    font-style: normal;
    font-weight: 400;
    unicode-range: U+0-FF, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: "Bree-W01-Thin-Oblique";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/4e33bf74-813a-4818-8313-6ea9039db056.eot?#iefix") format("eot"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/ceb3b4a3-0083-44ae-95cb-e362f95cc91b.woff2") format("woff2"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/4d716cea-5ba0-437a-b5a8-89ad159ea2be.woff") format("woff"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c458fc09-c8dd-4423-9767-e3e27082f155.ttf") format("truetype"), url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/85ffb31e-78ee-4e21-83d8-4313269135a9.svg#85ffb31e-78ee-4e21-83d8-4313269135a9") format("svg");
}

.bottom-shadow {
    box-shadow: 1px 1px 3px 1px;
}
.nophotofix{
    position: absolute;
    right: 0;
    transform: scale(0.8);
    transform-origin: top;
}
.memorial-book-form-field.email-dedic {
    margin: 0 10px;
}

.memorial-book-submit:hover {
    background-color: var(--global-hover-button-color);
    box-shadow: var(--global-button-shadow);
}

.memorial-book-submit {
    background-color: #fa0;
    width: 120px;
    height: 50px;
    align-content: center;
    display: flex;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    border: none;
    align-self: center;
    cursor: pointer;
    margin-bottom: 0 !important;
    margin-left: 10px;
}

.memorial-book-centered-data {
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.video-book {
    position: absolute;
    width: 100%;
    height: 83%;
    top: 0;
    bottom: 0;
    height: 100%;

}

.memorial-book-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
    object-fit: cover;
}

.book-page-label {
    text-decoration: none;
    font-size: 70px;
}

.z1 {
    z-index: 1;
}
.refresh-button{
    /*align-self: flex-end;*/
    /*margin-top: -20px;*/
    background: white ;
    border:1px solid rgba(102, 102, 102, 0.4);
    border-radius: 5px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.memorial-book-inner-div {
    display: flex;
    flex-direction: column;
    align-self: center;
    margin: 20px 0;
    width: 880px;
    height: 1200px;
}

.memorial-book-form {
    display: table;
    flex-direction: column;
    padding: 10px;
    box-shadow: 0.00px 1.00px 4px 0px rgba(0, 0, 0, 0.6);
}

.memorial-book-form-label {
    font: normal normal normal 26px/1.4em avenir-lt-w01_35-light1475496, sans-serif;
    font-family: futura-lt-w01-book, sans-serif;
    text-align: left;
    align-self: start;
    margin-left: 5px;
    margin-bottom: 10px;
    color: black;
}

.memorial-book-form-fields {
    display: flex;
    align-items: flex-start;
}

#book-form {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

}

.memorial-book-form-field {
    width: 40%;
}

.memorial-book-dedics {
    box-shadow: 0.00px 1.00px 4px 2px rgba(0, 0, 0, 0.6);
    height: auto;
    align-self: center;
}

.memorial-book-dedics {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    overflow-x: auto;
    width: 100%;

}

.PhoneInputInput{
    display: none;
}
.memorial-page-main-div #phone-label {
    position: absolute;
    left: 47px;
    padding: 2px;
    top: -7px;
    background: #fff;
    transition: .2s;
}



.video-container-container {
    display: block;
    position: inherit;
    bottom: 0;
}

.memorial-book-name {
    text-transform: capitalize;
}

.memorial-book-paginator {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 50px;
    margin: 20px 0;
}

.memorial-book-back {
    cursor: pointer;
    font-size: 50px !important;
    width: 50px;
}

.memorial-book-single-dedic {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: auto;
    min-height: 70px;
    border-bottom: 1px solid #d7c6a5;
    font-family: Cookie, sans-serif;
    font-size: 20px;
    font-weight: bold;
    padding-right: 20px;

}
@media(max-width: 830px){
    .memorial-book-single-dedic{
        height: auto;
    }
}
.memorial-book-table {
    /*min-width: 800px;*/
}

.memorial-book-dedic {
    padding: 0 20px;
    min-width: 70%;
}

.MuiMenuItem-root {
    overflow-x: auto !important;
}

@media (max-width: 941px) {
    .memorial-book-inner-div {
        width: 90% !important;
        max-width: 90% !important
    }
}

@media (max-width: 941px) {
    .memorial-book-inner-div {
        width: 100%;
        max-width: 100%
    }

    .memorial-phone-fix .phoneNumberfixmemorial {
        width: 100% !important;
        margin-bottom: 0 !important;
        margin-top: 20px !important;
    }
    .select-fix .memorial-book-form-field{
        width: 100% !important;
        margin-bottom: 8px;
    }
    .memorial-book-form {
        width: 100%;
        margin: 0 20px;
        align-items: center;
        max-width: 100%;
        align-self: center;
    }

    .memorial-book-form-field, .memorial-book-form-fields {
        width: 100%;
        max-width: 100%;
    }

    .memorial-book-form-field {
        margin-left: 0 !important;
        margin-top: 20px !important;
    }

    .memorial-book-submit {
        margin-top: 10px;
        /*margin-left: auto;*/
    }

    .memorial-book-form-field.email-dedic {
        margin: 0 0px;
    }

    #book-form {
        display: flex;
        flex-direction: column;
    }

    .book-page-label {
        width: 100% !important;
    }

    .memorial-book-inner-div {
        width: auto;
    }

}

@media (max-width: 540px) {
    .memorial-book-form {
        margin: 0 !important;
    }

    .book-page-label {
        font-size: 50px;
    }
}

@media (max-width: 390px) {
    .memorial-book-inner-div {
        width: 100%;
        padding: 0 20px;
    }

    .book-page-label {
        font-size: 40px;
    }
}

.paginator-fix {
    width: 960px;
    display: flex;
    align-self: center;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 20px;
}
